.delete-question{
    background-color: rgba(110, 112, 121, 0.1);
    background-repeat: no-repeat;
    background-position: center;
    font-size: 0;
    width: 40px;
    height: 40px;
    transition: 0.3s ease all;
    border-radius: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.1328 1.61778H16.1773V3.23556H14.5595V15.3689C14.5595 15.5834 14.4743 15.7892 14.3226 15.9409C14.1709 16.0926 13.9652 16.1778 13.7506 16.1778H2.42618C2.21165 16.1778 2.0059 16.0926 1.85421 15.9409C1.70251 15.7892 1.61729 15.5834 1.61729 15.3689V3.23556H-0.000488281V1.61778H4.04396V0H12.1328V1.61778ZM5.66173 5.66222V12.1333H7.27951V5.66222H5.66173ZM8.89729 5.66222V12.1333H10.5151V5.66222H8.89729Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
}
input[type=text].input-question,
textarea.input-question{
    border: 0.1px solid rgba(0,0,0,0.5);
    border-radius: 8px;
    background-color: transparent;
    padding: 14px 16px 14px 16px;
    font-size: 13px;
    line-height: 19.5px;
    margin-bottom: 30px;
}
.input-option{
    width: 100%;
    border: 0.1px solid rgba(0,0,0,0.5);
    border-radius: 8px;
    background-color: transparent;
    padding: 14px 35px 14px 35px;
    font-size: 13px;
    line-height: 19.5px;
}
.option-wrapper{
    &:before{
        content: '';
        height: 100%;
        width: 20px;
        position: absolute;
        left: 10px;
        top: 0px;
        background-position: center;
        background-repeat: no-repeat;
    }
    &.type-radio:before{
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7' cy='7' r='6' stroke='%23363F6C'/%3E%3C/svg%3E%0A");
    }
    &.type-checkbox:before{
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.3333 1H3.66667C2.19391 1 1 2.19391 1 3.66667V10.3333C1 11.8061 2.19391 13 3.66667 13H10.3333C11.8061 13 13 11.8061 13 10.3333V3.66667C13 2.19391 11.8061 1 10.3333 1Z' stroke='%23363F6C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
}
.button-del-form-option{
    position: absolute;
    top: 0;
    right: 10px;
    width: 20px;
    height: 100%;
    cursor: pointer;
    z-index: 10;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='10' viewBox='0 0 9 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 1.5L1 8.5' stroke='black' stroke-width='1.2' stroke-linecap='round'/%3E%3Cpath d='M1 1.5L8 8.5' stroke='black' stroke-width='1.2' stroke-linecap='round'/%3E%3C/svg%3E%0A");
}
.button-addmore-option{
    border: 2px solid #000000;
    border-radius: 20px;
    width: 180px;
    height: 45px;
    padding: 10px;    
}