.wallet-slider{
    .slick-slider{
        display: flex;
        align-items: center;

        .slick-slide{
            margin-right: 20px;
            width: 130px;
        }

        .slick-track{
            display: flex;
        }

        .slick-arrow{
            position: relative;
            font-size: 0;
            line-height: 0;
            width: 34px;
            height: 34px;
            border-radius: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            
            &.slick-prev{ 
                margin-right: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
            
            &.slick-next{ 
                background-image: url("data:image/svg+xml,%3Csvg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M14.5715 11.4214L20.4 17.25L14.5715 23.0786' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M14.5715 11.4214L20.4 17.25L14.5715 23.0786' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            }
            
            &.slick-disabled{
                opacity: 0;
                cursor: default;
            }
        }
        .slick-list{
            width:  calc( 100% - 70px);
            flex: 1;
        }
    }
}
.wallet-form{
    box-shadow: 0px 4px 30px 0px rgba(54, 63, 108, 0.1);
    border-radius: 20px;    
}