@import '../../assets/styles/utils/utils.scss';

.profile-header{
    background-image: url('../../assets/images/header-bg.png');
    background-position: top right;
    background-repeat: no-repeat;
    background-color: #2b3257;
    background-size: cover;
    color: var(--color-white);
        
    @media screen and (min-width:768px) {
      margin-bottom: 40px;
    }    
    
    h1{
        font-weight: 700;
        font-size: rem-calc(24px);
        line-height: rem-calc(30px);
        color:var(--color-white);
        text-transform: uppercase;
    }    
}

.profile-page{
    h2{
        color:var(--color-darkblue);
    }
}

.my-teams{
    .team{
        background: var(--color-white);
        box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
        border-radius: 12px;
        display: flex !important;
        .members{
            img{
                display: inline-block;
                border: 2px solid #ffffff;
            }
        }
        .title{
            font-weight: 600;
            font-size: rem-calc(14px);
            line-height: rem-calc(21px);
            color: var(--color-black);
        }
        .role{
            font-weight: 500;
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
            letter-spacing: 0.3px;
            color: var(--color-darkblue);
            &.isadmin{
                color: var(--color-green);
                padding-left: 18px;
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 11px;
                text-transform: uppercase;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.69069 0.666595H8.31469C10.1227 0.666595 11.3334 1.93593 11.3334 3.82393V8.1818C11.3334 10.0639 10.1227 11.3333 8.31469 11.3333H3.69069C1.88269 11.3333 0.666687 10.0639 0.666687 8.1818V3.82393C0.666687 1.93593 1.88269 0.666595 3.69069 0.666595ZM5.69594 7.59455L8.22927 5.06122C8.41061 4.87989 8.41061 4.58655 8.22927 4.39989C8.04794 4.21855 7.74927 4.21855 7.56794 4.39989L5.36527 6.60255L4.43194 5.66922C4.25061 5.48789 3.95194 5.48789 3.77061 5.66922C3.58927 5.85055 3.58927 6.14389 3.77061 6.33055L5.03994 7.59455C5.13061 7.68522 5.24794 7.72789 5.36527 7.72789C5.48794 7.72789 5.60527 7.68522 5.69594 7.59455Z' fill='%231AB900'/%3E%3C/svg%3E%0A");
            }
        }
    }
}
.children-list{
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: var(--color-gray-dark);
    .children{
        font-weight: 500;
        font-size: rem-calc(14px);
        line-height: rem-calc(21px);
        letter-spacing: 0.3px;
        color: var(--color-darkblue);
        padding: 4px 19px;
        border-radius: 14.5px;
        background-color: rgba(49, 57, 97, 0.05);
    }
}