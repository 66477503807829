@import '../../assets/styles/utils/utils.scss';
.page-search{
    .search-field-wrap{
        background-color: #F0F1F2;
    }
    .search-result-slider{
        margin-bottom: 40px;
        .slick-track{
            display: flex;
            .slick-slide{
                float: none;
                height: inherit;
                > div{
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .slick-slider{
            padding: 0px 20px;
            @media screen and (min-width:1024px) {
                padding: 0px 50px;
            }
            .slick-arrow{
                position: absolute;  
                top: 0;
                font-size: 0;
                line-height: 0;
                width: 33%;
                height: 100%;
                border-radius: 100%;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 34px 34px;
                z-index: 10;
                &.slick-prev{ 
                    left: 0;           
                    background-image: url("data:image/svg+xml,%3Csvg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-position: left center;
                }
                &.slick-next{ 
                    right: 0;           
                    background-image: url("data:image/svg+xml,%3Csvg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M14.5715 11.4214L20.4 17.25L14.5715 23.0786' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M14.5715 11.4214L20.4 17.25L14.5715 23.0786' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-position: right center;
                }
                &.slick-disabled{
                    opacity: 0.4;
                    cursor: default;
                }
            }
            .slick-track,
            .slick-list{
                transform: none;
            }
            .slick-slide{
                width: 336px;
                position: relative;
                transition: 0.2s ease-in-out transform;
                transform: scale(0.8) translateY(30px);
                .teamcard-content{
                    overflow: hidden;
                    &:after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgba(0,0,0,.5);
                        z-index: 2;
                    }
                }
                .logo-wrapper{
                    box-shadow: 0px 13px 11px -3px rgba(0, 0, 0, 0.25);
                    &:after{
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        background: rgba(0,0,0,.5);
                        z-index: 2;
                    }
                }
                &:hover{
                    .teamcard-content{
                        &:after{
                            width: 0;
                            height: 0;
                        }
                    }
                    .logo-wrapper{
                        &:after{
                            width: 0;
                            height: 0;
                        }
                    }
                }
                &.slick-active{
                    transform: scale(0.8) translate(-30px, 30px)
                }
                &.slick-center{
                    transform: none;
                    z-index: 30;
                    .teamcard-content{
                        &:after{
                            display: none;
                        }
                    }
                    .logo-wrapper{
                        &:after{
                            display: none;
                        }
                    }
                    + .slick-slide{
                        transform: scale(0.8) translate(30px, 30px);
                    }
                }
            }            
        }
        &.has-two{
            .slick-slider{
                .slick-slide{
                    &.slick-center{
                        + .slick-slide{
                            transform: scale(0.8) translateY(30px);
                        }
                    }
                }
            }
        }
        &.has-one{
            .slick-track{
                justify-content: center;
            }
        }
    }
    .teamcard{
        &-content{
            background: var(--color-white);
            box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
            border-radius: 12px;
            padding-top: 100px;
        }
        &-footer{
            border-top: 1px solid #363F6C;
            margin-top: 20px;
        }
        .members{
            img{
                display: inline-block;
                border: 2px solid #ffffff;
            }
        }
        .title{
            font-weight: 600;
            font-size: rem-calc(14px);
            line-height: rem-calc(21px);
            color: var(--color-dark-blue);
        }
        .role{
            font-weight: 500;
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
            letter-spacing: 0.3px;
            color: var(--color-green);
            padding-left: 18px;
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 11px;
            background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.69069 0.666595H8.31469C10.1227 0.666595 11.3334 1.93593 11.3334 3.82393V8.1818C11.3334 10.0639 10.1227 11.3333 8.31469 11.3333H3.69069C1.88269 11.3333 0.666687 10.0639 0.666687 8.1818V3.82393C0.666687 1.93593 1.88269 0.666595 3.69069 0.666595ZM5.69594 7.59455L8.22927 5.06122C8.41061 4.87989 8.41061 4.58655 8.22927 4.39989C8.04794 4.21855 7.74927 4.21855 7.56794 4.39989L5.36527 6.60255L4.43194 5.66922C4.25061 5.48789 3.95194 5.48789 3.77061 5.66922C3.58927 5.85055 3.58927 6.14389 3.77061 6.33055L5.03994 7.59455C5.13061 7.68522 5.24794 7.72789 5.36527 7.72789C5.48794 7.72789 5.60527 7.68522 5.69594 7.59455Z' fill='%231AB900'/%3E%3C/svg%3E%0A");
        }
        .distance{
            font-weight: bold;
            color: var(--color-dark-blue);                        
        }
    }
}

.plaisearchmap{
    position: relative;
    height: calc(100vh - 61px);
    width: 100%;
    overflow: hidden;
    
    .google-map {
        width: 100%;
        height: 100%;        
        .pin {
            display: flex;
            align-items: center;
            width: 200px;
        }      
        .pin-text {
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
            color: var(--color-dark-blue);
        }
    }
}