.leadership-row{
    background-color: #EFF1F9;
    border-radius: 18px;
    .indexnum{
        color: #000000;
    }
    .leadership-name{
        color: #000000;
    }
    .points{
        color: #000000;
    }
    &.hasdetail{
        &:hover{
            background-color: #363F6C;
            .indexnum{
                color: #ffffff;
            }
            .leadership-name{
                color: #ffffff;
            }
            .points{
                color: #ffffff;
            }
        }
    }
}