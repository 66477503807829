@import "../../assets/styles/utils/utils.scss";

.clubbed-card {
  background: #ffffff;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
  padding-left: 22.42px;
  padding-right: 22.42px;
}

.img-right-profile {
  position: relative;
  top: 40px;
  background-color: var(--color-gray-extralight);
  padding: 5px;
}

.gradient-1 {
  position: relative;
  top: 20px;
  width: 169.11px;
  height: 2px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fbdb09 0%,
    rgba(251, 219, 9, 0) 100%
  );
}

.gradient-2 {
  position: relative;
  bottom: 20px;
  width: 169.11px;
  height: 2px;
  background: radial-gradient(
    50% 50% at 50% 50%,
    #fbdb09 0%,
    rgba(251, 219, 9, 0) 100%
  );
}

.count-event {
  color: #fbdb09;
}

.category-count {
  width: 118px;
  height: 116px;
  background: #363f6c;
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .category {
    width: 66px;
    height: 24px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
  }
  .count {
    width: 44px;
    height: 51px;
    font-weight: 800;
    font-size: 34px;
    line-height: 51px;
    text-align: center;
    color: #fbdb09;
  }
}

.months {
  .slick-slider {
    padding: 0px 50px;

    .slick-arrow {
      position: absolute;
      top: calc(50% - 17px);
      font-size: 0;
      line-height: 0;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.slick-prev {
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' transform='matrix(-1 0 0 1 24.0898 0.192383)' fill='%23363F6C'/%3E%3Cpath d='M13.5063 16.0163C13.6238 15.9035 13.6898 15.7506 13.6898 15.5911C13.6898 15.4316 13.6238 15.2786 13.5063 15.1659L10.4031 12.1888L13.5063 9.21164C13.6205 9.09821 13.6837 8.94629 13.6822 8.78859C13.6808 8.6309 13.6149 8.48005 13.4986 8.36854C13.3824 8.25703 13.2252 8.19378 13.0608 8.19241C12.8964 8.19103 12.7381 8.25166 12.6198 8.36121L9.07338 11.7635C8.95586 11.8763 8.88983 12.0293 8.88983 12.1888C8.88983 12.3482 8.95586 12.5012 9.07338 12.614L12.6198 16.0163C12.7374 16.129 12.8968 16.1924 13.0631 16.1924C13.2293 16.1924 13.3887 16.129 13.5063 16.0163Z' fill='white'/%3E%3C/svg%3E");
      }

      &.slick-next {
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.0898' cy='12.1924' r='12' fill='%23363F6C'/%3E%3Cpath d='M10.6734 16.0163C10.5559 15.9035 10.4899 15.7506 10.4899 15.5911C10.4899 15.4316 10.5559 15.2786 10.6734 15.1659L13.7766 12.1888L10.6734 9.21164C10.5592 9.09821 10.496 8.94629 10.4974 8.78859C10.4989 8.6309 10.5648 8.48005 10.681 8.36854C10.7973 8.25703 10.9545 8.19378 11.1189 8.19241C11.2833 8.19103 11.4416 8.25166 11.5599 8.36121L15.1063 11.7635C15.2238 11.8763 15.2899 12.0293 15.2899 12.1888C15.2899 12.3482 15.2238 12.5012 15.1063 12.614L11.5599 16.0163C11.4423 16.129 11.2829 16.1924 11.1166 16.1924C10.9504 16.1924 10.791 16.129 10.6734 16.0163Z' fill='white'/%3E%3C/svg%3E");
      }

      &.slick-disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }

  .slick-slide {
    padding: 34px 10px;
    max-width: 430px;
    p {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      color: #000000;
    }
    &.slick-current{
      p{
        font-weight: 800;
      }
    }
  }

  .slick-track {
    margin: 0;
    .active {
      font-family: "Poppins";
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: #000000;
    }
  }

  /*.slick-slide:not(.slick-active) {
    opacity: 0.1;
  }*/
}

.days {
  padding-bottom: 38px;
  .slick-slider {
    /*.slick-arrow {
      display: none !important;
    }*/
    .slick-arrow {
      position: absolute;
      top: calc(50% - 17px);
      font-size: 0;
      line-height: 0;
      width: 34px;
      height: 34px;
      border-radius: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 100;
      &.slick-prev {
        left: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12' cy='12' r='12' transform='matrix(-1 0 0 1 24.0898 0.192383)' fill='%23363F6C'/%3E%3Cpath d='M13.5063 16.0163C13.6238 15.9035 13.6898 15.7506 13.6898 15.5911C13.6898 15.4316 13.6238 15.2786 13.5063 15.1659L10.4031 12.1888L13.5063 9.21164C13.6205 9.09821 13.6837 8.94629 13.6822 8.78859C13.6808 8.6309 13.6149 8.48005 13.4986 8.36854C13.3824 8.25703 13.2252 8.19378 13.0608 8.19241C12.8964 8.19103 12.7381 8.25166 12.6198 8.36121L9.07338 11.7635C8.95586 11.8763 8.88983 12.0293 8.88983 12.1888C8.88983 12.3482 8.95586 12.5012 9.07338 12.614L12.6198 16.0163C12.7374 16.129 12.8968 16.1924 13.0631 16.1924C13.2293 16.1924 13.3887 16.129 13.5063 16.0163Z' fill='white'/%3E%3C/svg%3E");
      }

      &.slick-next {
        right: 0;
        background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.0898' cy='12.1924' r='12' fill='%23363F6C'/%3E%3Cpath d='M10.6734 16.0163C10.5559 15.9035 10.4899 15.7506 10.4899 15.5911C10.4899 15.4316 10.5559 15.2786 10.6734 15.1659L13.7766 12.1888L10.6734 9.21164C10.5592 9.09821 10.496 8.94629 10.4974 8.78859C10.4989 8.6309 10.5648 8.48005 10.681 8.36854C10.7973 8.25703 10.9545 8.19378 11.1189 8.19241C11.2833 8.19103 11.4416 8.25166 11.5599 8.36121L15.1063 11.7635C15.2238 11.8763 15.2899 12.0293 15.2899 12.1888C15.2899 12.3482 15.2238 12.5012 15.1063 12.614L11.5599 16.0163C11.4423 16.129 11.2829 16.1924 11.1166 16.1924C10.9504 16.1924 10.791 16.129 10.6734 16.0163Z' fill='white'/%3E%3C/svg%3E");
      }

      &.slick-disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
  .slick-slide {
    padding: 0px 10px;
    max-width: 430px;

    .day-circle {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .circle-day {
      width: 60px;
      height: 60px;
      border-radius: 100%;
      background: #d5d5d5;
      p {
        color: black;
        opacity: 0.6;
      }
      &.active {
        background: #363f6c;
        color: white;
        opacity: unset !important;
        font-weight: bolder !important;

        p {
          color: white;
          opacity: unset !important;
        }
      }
    }
  }
  .slick-track {
    margin: 0;

    .active {
      font-weight: bolder;
      opacity: unset;
    }
  }
  .slick-slide:not(.slick-active) {
    opacity: 0.15;
  }
}
.divider {
  width: 100%;
  opacity: 0.2;
  /* DARK BLUE */
  border: 1.5px solid #363f6c;
}

.fc-scrollgrid {
  border: none !important;
}

.fc .fc-scrollgrid-section-liquid > td {
  border-right: none !important;
  border-bottom: none !important;
}

.slot-lane {
  background-color: #fff !important;
  height: 7.625rem !important; // 1.5em by default
  border-bottom: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.slot-label {
  border: none !important;
}

.fc-timegrid-col {
  border: none !important;
}

.fc .fc-timegrid-slot-label {
  vertical-align: top !important;
}

.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: left !important;
}

.cell-card {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #363f6c;
  border-radius: 14px;
  &.pop-up {
    margin: 6px;
    width: 100%;
    min-height: 110px;
    position: static;
    a {
      border-radius: 14px;
    }
  }
  .plaisport-vector {
    position: absolute;
  }
  .cell-img {
    margin-left: 27.26px;
    max-width: 100px;
    width: 100%;
    height: 100%;
    align-items: center;
    img {
      width: 60px;
      height: 60px;
      border: 2px solid white;
      border-radius: 100%;
    }
  }
  .cell-title {
    padding: 16px;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: flex-start;
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;
    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.6;
    }
    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
  .cell-description {
    margin-left: 70.8px;
    width: 100%;
    height: 100%;
    justify-content: center;
    font-family: "Poppins";
    font-style: normal;
    color: #ffffff;

    .title {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.6;
    }

    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }
}

.fc-timegrid-event-harness {
  cursor: pointer;
  margin: 6px;
  border-radius: 14px;
  max-height: 80px;
  /*max-width: 140px;*/
  a {
    border-radius: 14px;
  }
}

.close-popup {
  background-color: white;
  top: -15px;
  right:-15px;
  &:hover {
    background-color: #363f6c;
  }
}

input[type=text].yearPicker {
  text-align: center;
  padding: 14px 16px;
  margin-bottom: 15px;
  background-color: #363f6c;
  color: #ffffff;
}
