.discount-coupon{
    position: relative;
    background-image:url('../../../assets/images/ticket-bg.svg');
    background-repeat: no-repeat;
    background-position: center;
    &.active{
        background-image:url('../../../assets/images/ticket-bg-active.svg');
    }
    .col-1{
        padding-left: 16px;
        padding-right: 5px;
    }
    .line{
        width: 1px;
        border-right: 1px dashed rgb(0 0 0 / 5%);
        height: 100%;
    }
    .col-2{
        padding-right: 23px;
    }   
}
