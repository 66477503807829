/* ==========================================================================
 # Variables (CSS)
========================================================================== */

:root {

    /* Basic colors
    ----------------------------------------------- */
    --color-yellow:           #FBDB09;
    --color-darkblue:         #363F6C;
    --color-black:            #000000;
    --color-white:            #ffffff; 
    --color-gray-dark:        #53545C;
    --color-gray-light:       #6E7079;
    --color-gray-light2:      #8B8D97;
    --color-gray-extralight:  #F0F1F2;
    --color-gray:             #D5D5D5;
    --color-green:            #1AB900;
    --color-red:              #FF0000;
    
    --color-status-light-green:#BBE3C9;
    --color-status-green:     #065012;
    --color-status-light-red: #FDE1E1;
    --color-status-red:       #A83B3B;

    /* Context colors
    ----------------------------------------------- */
    --color-success:          #98C93C;
    --color-error:            #D95757;
  
    /* Typography
    ----------------------------------------------- */
  
    --font-weight-heavy:  900;
    --font-weight-extrabold:  800;
    --font-weight-bold:   700;
    --font-weight-semibold: 600;
    --font-weight-medium: 500;    
    --font-weight-normal: 400;
    --font-weight-light:  300;
}
  