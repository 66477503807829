@import '../../assets/styles/utils/utils.scss';

.file-uploader{
    padding: 10px;
    background: #EFF1F9;
    border-radius: 8px;
}

.file-uploader-wrapper{
    border: 1px dashed #363F6C;
    height: 236px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .upload-icon{
        width: 75px;
        height: 63px;
        background-image: url('../../assets/images/icon-uploadimage.svg');
        background-position: center;
        background-repeat: no-repeat;
        margin-bottom: 12px;
    }
    .upload-text{
        h4{
            font-weight: 500;
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);
            text-decoration: underline;
            color: var(--color-darkblue);
            margin-bottom: 3px;
            text-align: center;
        }
        p{
            color: #53545C;
            font-style: italic;
            font-weight: 300;
            font-size: rem-calc(12px);
            line-height: rem-calc(14px);
        }
    }
}