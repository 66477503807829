@import '../../assets/styles/utils/utils.scss';

.site-sidebar{
    background-color: var(--color-darkblue);
    left:-18.5rem;
    width: 18.5rem;
    overflow-x: hidden;
    .main-menu{
        a{
            padding: 12px 16px 12px 16px;
            img{
                margin-right: 16px;
            }
        }
    }
    @media screen and (min-width: 1024px) {
        &.sidebar-thin{
            width: 88px;
            .menu-wrapper{
                padding-top: 189px;
            }
            .wlcmsg{
                display: none;
            }
            .main-menu{
                a{
                    padding: 12px 0 12px 0;
                    img{
                        margin-right: 0;
                    }
                    span{
                        display: none;
                    }
                }
            }
            .logout-btn{
                span{
                    display: none;
                }
            }
        }
        .menu-collapse-switch{
            width: 35px;
            height: 35px;
            position: absolute;
            right: 10px; 
            top: 100px;
            background-image: url("data:image/svg+xml,%3Csvg width='34' height='35' viewBox='0 0 34 35' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Crect y='0.25' width='34' height='34' rx='17' fill='%23FBDB09'/%3E%3Cpath d='M19.55 22.35L14.45 17.25L19.55 12.15' stroke='black' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            span{
                display: none;
            }
            &.shrink{
                transform:rotate(180deg);
            }
        }
    }
    &.expand{
        left:0;
    }
    @media screen and (min-width: 1024px) {
        left: 0;
    }
    .site-logo{
        padding: 19px 20px 19px 20px;
        height: rem-calc(82px);
        background-color: var(--color-white);
        @media screen and (min-width: 1024px) {
            height: rem-calc(87px);
        }
        @media screen and (min-width:1280px) {
            padding: 19px 0 19px 80px;
        }
        &.sidebar-thin{
            @media screen and (min-width:1280px) {
                background-color: var(--color-gray-extralight);
                padding: 19px 0 19px 0;
            }
        }
    }
    .wlcmsg{
        color: var(--color-white);
        font-weight: 500;
        font-size: rem-calc(20px);
        line-height: rem-calc(30px);
        text-transform: uppercase;
        span{
            font-weight: 700;
            font-size: rem-calc(26px);
            line-height: rem-calc(39px);
            text-transform: uppercase;
        }
    }
    .logout-btn{
        font-weight: 400;
        font-size: rem-calc(14px);
        line-height: rem-calc(21px);
        color: var(--color-white);
        padding-left: 39px;
        &::before{
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='12.4301' cy='12' r='12' fill='%23FBDB03'/%3E%3Cpath d='M16.4301 15.4289H15.2873V16.5718H9.00155V7.42893H15.2873V8.57179H16.4301V6.8575C16.4301 6.70595 16.3699 6.5606 16.2628 6.45344C16.1556 6.34628 16.0102 6.28607 15.8587 6.28607H8.43012C8.27857 6.28607 8.13323 6.34628 8.02606 6.45344C7.9189 6.5606 7.85869 6.70595 7.85869 6.8575V17.1432C7.85869 17.2948 7.9189 17.4401 8.02606 17.5473C8.13323 17.6544 8.27857 17.7146 8.43012 17.7146H15.8587C16.0102 17.7146 16.1556 17.6544 16.2628 17.5473C16.3699 17.4401 16.4301 17.2948 16.4301 17.1432V15.4289ZM15.2873 11.4289H11.2873V12.5718H15.2873V14.2861L18.1444 12.0004L15.2873 9.71464V11.4289Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            width: 24px;
            height: 24px;
            position: absolute;
            left: 0;
            top: 0;
        }
        &:hover{
            color: var(--color-yellow);
        }
    }
}