.calander-events-monthview{
    .react-calendar{
        color: var(--color-white);
        background-color: var(--color-darkblue);
        border-radius: 10px;
        border:none;

        &__navigation{
            button{
                &:enabled{
                    &:focus, &:hover{
                        background-color: var(--color-yellow);
                        color:var(--color-darkblue);
                    }
                }
            }
        }

        &__tile{
            width: 50px;
            height: 50px;
            position: relative;
            color: var(--color-white);

            &:enabled{
                &:focus,&:hover{
                    background-color: var(--color-yellow);
                    color:var(--color-darkblue);
                }
            }

            &--active{
                background-color: var(--color-darkblue);
                
                abbr{
                    width: 22px;
                    height: 22px;
                    color: var(--color-darkblue);
                    background-color: var(--color-yellow);
                    border-radius: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                }
            }

            &.has-event{
                &:enabled{
                    &:focus,&:hover{
                        background-color: var(--color-darkblue);
                        color:var(--color-white);

                        abbr{
                            width: 22px;
                            height: 22px;
                            color: var(--color-darkblue);
                            background-color: var(--color-yellow);
                            border-radius: 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            margin: 0 auto;
                        }
                    }
                }                
            }
        }        
    }    
}