.your-forms-grid{
    .form-card{
        box-shadow: 0px 10px 34px 0px rgba(197, 207, 255, 0.2);
        border-radius: 20px;
        padding-bottom: 71px;
        
        &-title{
            letter-spacing: 0.02em;
        }

        .completed-status{
            background: rgba(26, 185, 0, 0.1);
            border-top-left-radius: 20px;
            border-bottom-right-radius: 20px;
            color: #1AB900;
            padding: 13px 21px 14px;
        }
        .notstarted-status{
            background: rgba(242, 169, 59, 0.1);            
            border-top-left-radius: 20px;
            border-bottom-right-radius: 20px;
            color: #F2A93B;
            padding: 13px 21px 14px;
        }
    }
}