@import '../../assets/styles/utils/utils.scss';

.comment-item{
    .comment-detail{
        border-radius: 10px;
        background-color: var(--color-white);
        padding: 9px 12px 9px 12px;
    }
    .comment-by{
        font-weight: 500;
        font-size: rem-calc(14px);
        line-height: rem-calc(21px);
        color: #2B2829;
    }
    .comment-on{
        font-weight: 400;
        font-size: rem-calc(10px);
        line-height: rem-calc(15px);
        color: #2B2829;
        opacity: 0.5;
    }
    .comment-text{
        font-weight: 400;
        font-size: rem-calc(14px);
        line-height: rem-calc(21px);
        color: #53545C;
    }
}

.comment-submit-btn{
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-position: center;
    background-color: var(--color-darkblue);
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' viewBox='0 0 15 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.51673 4.46146L1.49588 4.46598H10.4339L7.62409 1.67396C7.4865 1.53765 7.41103 1.35299 7.41103 1.15919C7.41103 0.965379 7.4865 0.782015 7.62409 0.64538L8.06129 0.211681C8.19877 0.0753697 8.38197 0 8.57733 0C8.7728 0 8.95611 0.0748314 9.09359 0.211143L13.9101 4.98625C14.0481 5.1231 14.1236 5.30538 14.123 5.4993C14.1236 5.69429 14.0481 5.87669 13.9101 6.01332L9.09359 10.7889C8.95611 10.9251 8.77291 11 8.57733 11C8.38197 11 8.19877 10.925 8.06129 10.7889L7.62409 10.3552C7.4865 10.2191 7.41103 10.0373 7.41103 9.8435C7.41103 9.6498 7.4865 9.47764 7.62409 9.34143L10.4656 6.5338H1.50674C1.10418 6.5338 0.765904 6.18979 0.765904 5.79087V5.17747C0.765904 4.77855 1.11417 4.46146 1.51673 4.46146Z' fill='%23FBDB03'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    span{
        display: none;
    }
}
.comment-form{
    background: #FFFFFF;
    border: 1px solid #E2E2E2;
    border-radius: 12px;
    width: 100%;
    max-width: 845px;
    padding: 15px 15px 15px 30px;
    input{
        border: none;
        background-color: var(--color-white);
        padding: 0;
        font-weight: 500;
        font-size: rem-calc(16px);
        line-height: rem-calc(24px);
        color: #7D7D7D;
        border-radius: 0;
        &:focus{
            background-color: var(--color-white);
        }
    }
}