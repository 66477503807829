@import '../../../assets/styles/utils/utils.scss';

.fantacygame-header{
    background-color: var(--color-darkblue);
    background-image: url('../../../assets/images/fantacy-headbg.png');
    background-position: right top;
    background-repeat: no-repeat;
}
.plus-btn{
    width: 22px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.6697 1.6709H6.44748C3.74742 1.6709 1.55859 3.85973 1.55859 6.55979V18.782C1.55859 21.4821 3.74742 23.6709 6.44748 23.6709H18.6697C21.3698 23.6709 23.5586 21.4821 23.5586 18.782V6.55979C23.5586 3.85973 21.3698 1.6709 18.6697 1.6709Z' stroke='%23363F6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.5586 7.28809V18.0537' stroke='%23363F6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.17578 12.6709H17.9414' stroke='%23363F6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.minus-btn{
    width: 22px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.6697 1.6709H6.44748C3.74742 1.6709 1.55859 3.85973 1.55859 6.55979V18.782C1.55859 21.4821 3.74742 23.6709 6.44748 23.6709H18.6697C21.3698 23.6709 23.5586 21.4821 23.5586 18.782V6.55979C23.5586 3.85973 21.3698 1.6709 18.6697 1.6709Z' stroke='%23363F6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M7.17578 12.6709H17.9414' stroke='%23363F6C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.startbtn{
    background-color: transparent;
    border: none;
    outline: none;
    cursor: pointer;

    &.on {
        color: #FBDB03;
    }
    &.off {
        color: #ccc;
    }
}
.player-catg-slider{
    .slick-arrow{
        position: absolute;  
        top: calc(50% - 17px);          
        font-size: 0;
        line-height: 0;
        width: 34px;
        height: 34px;
        border-radius: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        &.slick-prev{ 
            left: -30px;           
            background-image: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15' cy='15' r='15' transform='matrix(-1 0 0 1 30.7441 0.923096)' fill='%23363F6C'/%3E%3Cpath d='M17.5147 20.703C17.6616 20.562 17.7441 20.3708 17.7441 20.1715C17.7441 19.9721 17.6616 19.7809 17.5147 19.6399L13.6357 15.9186L17.5147 12.1972C17.6574 12.0554 17.7364 11.8655 17.7346 11.6684C17.7329 11.4712 17.6504 11.2827 17.5052 11.1433C17.3599 11.0039 17.1633 10.9248 16.9578 10.9231C16.7524 10.9214 16.5544 10.9972 16.4066 11.1341L11.9736 15.387C11.8267 15.528 11.7441 15.7192 11.7441 15.9186C11.7441 16.1179 11.8267 16.3091 11.9736 16.4501L16.4066 20.703C16.5536 20.8439 16.7529 20.9231 16.9607 20.9231C17.1685 20.9231 17.3677 20.8439 17.5147 20.703Z' fill='white'/%3E%3C/svg%3E%0A");
        }
        &.slick-next{ 
            right: -30px;           
            background-image: url("data:image/svg+xml,%3Csvg width='31' height='31' viewBox='0 0 31 31' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='15.7441' cy='15.9232' r='15' fill='%23363F6C'/%3E%3Cpath d='M13.9736 20.7031C13.8267 20.5621 13.7441 20.3709 13.7441 20.1716C13.7441 19.9722 13.8267 19.781 13.9736 19.6401L17.8526 15.9187L13.9736 12.1973C13.8308 12.0555 13.7518 11.8656 13.7536 11.6685C13.7554 11.4714 13.8378 11.2828 13.9831 11.1434C14.1284 11.004 14.325 10.925 14.5304 10.9232C14.7359 10.9215 14.9339 10.9973 15.0816 11.1343L19.5147 15.3872C19.6616 15.5281 19.7441 15.7193 19.7441 15.9187C19.7441 16.118 19.6616 16.3092 19.5147 16.4502L15.0816 20.7031C14.9347 20.844 14.7354 20.9232 14.5276 20.9232C14.3198 20.9232 14.1205 20.844 13.9736 20.7031Z' fill='white'/%3E%3C/svg%3E%0A");
        }
        &.slick-disabled{
            opacity: 0;
            cursor: default;
        }
    }
}
.player-item{
    background-color: var(--color-white);
    box-shadow: 0px 6px 30px rgba(54, 63, 108, 0.1);
    
    .player-name{
        color: var(--color-darkblue);
    }

    &-selected{
        background-color: var(--color-darkblue);
        color: var(--color-white);

        .player-name{
            color: var(--color-white);
        }
    }
    
    .trending{        
        &-up{
            background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05615 0.2771L4.07688 3.7771H0.0354264L2.05615 0.2771ZM2.40615 3.4271V12.7199H1.70615V3.4271H2.40615Z' fill='%231AB900'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            width: 5px;
            height: 13px;
            margin: 0 5px;
        }
        &-down{
            background-image: url("data:image/svg+xml,%3Csvg width='5' height='13' viewBox='0 0 5 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.05615 12.72L4.07688 9.21997H0.0354264L2.05615 12.72ZM2.40615 9.56997V0.277155H1.70615V9.56997H2.40615Z' fill='%23FF0000'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
            width: 5px;
            height: 13px;
            margin: 0 5px;
        }
    }
    .ratings{
        margin-left: 10px;
        .star{
            color: #FBDB03;
            margin: 0 2.5px;
        }
    }
    .player-btn{
        width: 30px;
        height: 31px;
        &-add{
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.31348 0.697754C8.86576 0.697754 9.31348 1.14547 9.31348 1.69775V15.6978C9.31348 16.25 8.86576 16.6978 8.31348 16.6978C7.76119 16.6978 7.31348 16.25 7.31348 15.6978V1.69775C7.31348 1.14547 7.76119 0.697754 8.31348 0.697754Z' fill='%23363F6C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3135 8.69775C16.3135 9.25004 15.8658 9.69775 15.3135 9.69775L1.31348 9.69775C0.761192 9.69775 0.313476 9.25004 0.313477 8.69775C0.313477 8.14547 0.761192 7.69775 1.31348 7.69775L15.3135 7.69775C15.8658 7.69775 16.3135 8.14547 16.3135 8.69775Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-color: rgba(54, 63, 108, 0.1);
            &:hover{
                background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.31348 0.697754C8.86576 0.697754 9.31348 1.14547 9.31348 1.69775V15.6978C9.31348 16.25 8.86576 16.6978 8.31348 16.6978C7.76119 16.6978 7.31348 16.25 7.31348 15.6978V1.69775C7.31348 1.14547 7.76119 0.697754 8.31348 0.697754Z' fill='%23ffffff'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3135 8.69775C16.3135 9.25004 15.8658 9.69775 15.3135 9.69775L1.31348 9.69775C0.761192 9.69775 0.313476 9.25004 0.313477 8.69775C0.313477 8.14547 0.761192 7.69775 1.31348 7.69775L15.3135 7.69775C15.8658 7.69775 16.3135 8.14547 16.3135 8.69775Z' fill='%23ffffff'/%3E%3C/svg%3E%0A");
                background-color: rgba(54, 63, 108, 1);
            }
        }
        &-remove{
            background-image: url("data:image/svg+xml,%3Csvg width='17' height='3' viewBox='0 0 17 3' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.3135 1.39722C16.3135 1.9495 15.8658 2.39722 15.3135 2.39722L1.31348 2.39722C0.761192 2.39722 0.313476 1.9495 0.313477 1.39722C0.313477 0.844931 0.761192 0.397216 1.31348 0.397216L15.3135 0.397217C15.8658 0.397217 16.3135 0.844932 16.3135 1.39722Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
            background-position: center;
            background-repeat: no-repeat;
            background-color: #FBDB03;
            &:hover{
                background-color: #ffffff;
            }
        }
    }
}
.point-rewards{
    .captain{
        span{
            width: 34px;
            height: 35px;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.07165 0.275269C6.07805 0.275269 6.97112 0.524602 7.75085 1.02327C8.53965 1.51287 9.11085 2.211 9.46445 3.11767H7.60125C7.35645 2.6462 7.01645 2.2926 6.58125 2.05687C6.14605 1.81207 5.64285 1.68967 5.07165 1.68967C4.44605 1.68967 3.88845 1.8302 3.39885 2.11127C2.90925 2.39234 2.52392 2.79127 2.24285 3.30807C1.97085 3.82487 1.83485 4.42327 1.83485 5.10327C1.83485 5.78327 1.97085 6.3862 2.24285 6.91207C2.52392 7.42887 2.90925 7.8278 3.39885 8.10887C3.88845 8.38994 4.44605 8.53047 5.07165 8.53047C5.64285 8.53047 6.14605 8.4126 6.58125 8.17687C7.01645 7.93207 7.35645 7.57394 7.60125 7.10247H9.46445C9.11085 8.00914 8.53965 8.70727 7.75085 9.19687C6.97112 9.68647 6.07805 9.93127 5.07165 9.93127C4.18312 9.93127 3.37165 9.72727 2.63725 9.31927C1.90285 8.9022 1.31805 8.32647 0.882852 7.59207C0.456719 6.85767 0.243652 6.02807 0.243652 5.10327C0.243652 4.17847 0.456719 3.34887 0.882852 2.61447C1.31805 1.88007 1.90285 1.30887 2.63725 0.900869C3.38072 0.483802 4.19219 0.275269 5.07165 0.275269Z' fill='white'/%3E%3C/svg%3E%0A");
            background-color: #FF0000;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
            border-radius: 100%;            
        }
        &.active{
            span{
                background-color: #1AB900;
            }
        }
    }
    .vice-captain{
        span{
            width: 34px;
            height: 35px;
            background-image: url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.93654 0.397669L4.72454 8.24487L7.53974 0.397669H9.18534L5.63574 9.84967H3.84054L0.277344 0.397669H1.93654Z' fill='white'/%3E%3Cpath d='M14.7931 0.275269C15.7995 0.275269 16.6926 0.524602 17.4723 1.02327C18.2611 1.51287 18.8323 2.211 19.1859 3.11767H17.3227C17.0779 2.6462 16.7379 2.2926 16.3027 2.05687C15.8675 1.81207 15.3643 1.68967 14.7931 1.68967C14.1675 1.68967 13.6099 1.8302 13.1203 2.11127C12.6307 2.39234 12.2454 2.79127 11.9643 3.30807C11.6923 3.82487 11.5563 4.42327 11.5563 5.10327C11.5563 5.78327 11.6923 6.3862 11.9643 6.91207C12.2454 7.42887 12.6307 7.8278 13.1203 8.10887C13.6099 8.38994 14.1675 8.53047 14.7931 8.53047C15.3643 8.53047 15.8675 8.4126 16.3027 8.17687C16.7379 7.93207 17.0779 7.57394 17.3227 7.10247H19.1859C18.8323 8.00914 18.2611 8.70727 17.4723 9.19687C16.6926 9.68647 15.7995 9.93127 14.7931 9.93127C13.9046 9.93127 13.0931 9.72727 12.3587 9.31927C11.6243 8.9022 11.0395 8.32647 10.6043 7.59207C10.1782 6.85767 9.96511 6.02807 9.96511 5.10327C9.96511 4.17847 10.1782 3.34887 10.6043 2.61447C11.0395 1.88007 11.6243 1.30887 12.3587 0.900869C13.1022 0.483802 13.9136 0.275269 14.7931 0.275269Z' fill='white'/%3E%3C/svg%3E%0A");
            background-color: #FF0000;
            background-position: center;
            background-repeat: no-repeat;
            margin-right: 8px;
            border-radius: 100%;            
        }
        &.active{
            span{
                background-color: #1AB900;
            }
        }
    }
}
.joinplayerlist{
    margin-bottom: 15px;
    padding: 20px 0;    
}
.btn-captain{
    width: 34px;
    height: 34px;
    background-image: url("data:image/svg+xml,%3Csvg width='10' height='11' viewBox='0 0 10 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.38366 0.67688C6.39006 0.67688 7.28313 0.926213 8.06286 1.42488C8.85167 1.91448 9.42286 2.61261 9.77646 3.51928H7.91326C7.66846 3.04781 7.32846 2.69421 6.89326 2.45848C6.45806 2.21368 5.95486 2.09128 5.38366 2.09128C4.75806 2.09128 4.20046 2.23181 3.71086 2.51288C3.22126 2.79395 2.83593 3.19288 2.55486 3.70968C2.28286 4.22648 2.14686 4.82488 2.14686 5.50488C2.14686 6.18488 2.28286 6.78781 2.55486 7.31368C2.83593 7.83048 3.22126 8.22941 3.71086 8.51048C4.20046 8.79155 4.75806 8.93208 5.38366 8.93208C5.95486 8.93208 6.45806 8.81421 6.89326 8.57848C7.32846 8.33368 7.66846 7.97555 7.91326 7.50408H9.77646C9.42286 8.41075 8.85167 9.10888 8.06286 9.59848C7.28313 10.0881 6.39006 10.3329 5.38366 10.3329C4.49513 10.3329 3.68366 10.1289 2.94926 9.72088C2.21486 9.30381 1.63006 8.72808 1.19486 7.99368C0.768731 7.25928 0.555664 6.42968 0.555664 5.50488C0.555664 4.58008 0.768731 3.75048 1.19486 3.01608C1.63006 2.28168 2.21486 1.71048 2.94926 1.30248C3.69273 0.885413 4.5042 0.67688 5.38366 0.67688Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
    border-radius: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(54, 63, 108, 0.15);
    &:hover{
        background-color: rgba(54, 63, 108, 0.25);
    }
    &.active{
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.07165 0.275269C6.07805 0.275269 6.97112 0.524602 7.75085 1.02327C8.53965 1.51287 9.11085 2.211 9.46445 3.11767H7.60125C7.35645 2.6462 7.01645 2.2926 6.58125 2.05687C6.14605 1.81207 5.64285 1.68967 5.07165 1.68967C4.44605 1.68967 3.88845 1.8302 3.39885 2.11127C2.90925 2.39234 2.52392 2.79127 2.24285 3.30807C1.97085 3.82487 1.83485 4.42327 1.83485 5.10327C1.83485 5.78327 1.97085 6.3862 2.24285 6.91207C2.52392 7.42887 2.90925 7.8278 3.39885 8.10887C3.88845 8.38994 4.44605 8.53047 5.07165 8.53047C5.64285 8.53047 6.14605 8.4126 6.58125 8.17687C7.01645 7.93207 7.35645 7.57394 7.60125 7.10247H9.46445C9.11085 8.00914 8.53965 8.70727 7.75085 9.19687C6.97112 9.68647 6.07805 9.93127 5.07165 9.93127C4.18312 9.93127 3.37165 9.72727 2.63725 9.31927C1.90285 8.9022 1.31805 8.32647 0.882852 7.59207C0.456719 6.85767 0.243652 6.02807 0.243652 5.10327C0.243652 4.17847 0.456719 3.34887 0.882852 2.61447C1.31805 1.88007 1.90285 1.30887 2.63725 0.900869C3.38072 0.483802 4.19219 0.275269 5.07165 0.275269Z' fill='white'/%3E%3C/svg%3E%0A");
        background-color: #1AB900;
        &:hover{
            background-color: #1AB900;
        }
    }
}
.btn-vcaptain{
    width: 34px;
    height: 34px;
    border-radius: 100%;
    background-image: url("data:image/svg+xml,%3Csvg width='20' height='11' viewBox='0 0 20 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.24807 0.79928L5.03607 8.64648L7.85127 0.79928H9.49687L5.94727 10.2513H4.15207L0.588867 0.79928H2.24807Z' fill='%23363F6C'/%3E%3Cpath d='M15.1046 0.67688C16.111 0.67688 17.0041 0.926213 17.7838 1.42488C18.5726 1.91448 19.1438 2.61261 19.4974 3.51928H17.6342C17.3894 3.04781 17.0494 2.69421 16.6142 2.45848C16.179 2.21368 15.6758 2.09128 15.1046 2.09128C14.479 2.09128 13.9214 2.23181 13.4318 2.51288C12.9422 2.79395 12.5569 3.19288 12.2758 3.70968C12.0038 4.22648 11.8678 4.82488 11.8678 5.50488C11.8678 6.18488 12.0038 6.78781 12.2758 7.31368C12.5569 7.83048 12.9422 8.22941 13.4318 8.51048C13.9214 8.79155 14.479 8.93208 15.1046 8.93208C15.6758 8.93208 16.179 8.81421 16.6142 8.57848C17.0494 8.33368 17.3894 7.97555 17.6342 7.50408H19.4974C19.1438 8.41075 18.5726 9.10888 17.7838 9.59848C17.0041 10.0881 16.111 10.3329 15.1046 10.3329C14.2161 10.3329 13.4046 10.1289 12.6702 9.72088C11.9358 9.30381 11.351 8.72808 10.9158 7.99368C10.4897 7.25928 10.2766 6.42968 10.2766 5.50488C10.2766 4.58008 10.4897 3.75048 10.9158 3.01608C11.351 2.28168 11.9358 1.71048 12.6702 1.30248C13.4137 0.885413 14.2252 0.67688 15.1046 0.67688Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgba(54, 63, 108, 0.15);
    &:hover{
        background-color: rgba(54, 63, 108, 0.25);
    }
    &.active{
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='10' viewBox='0 0 20 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.93654 0.397669L4.72454 8.24487L7.53974 0.397669H9.18534L5.63574 9.84967H3.84054L0.277344 0.397669H1.93654Z' fill='white'/%3E%3Cpath d='M14.7931 0.275269C15.7995 0.275269 16.6926 0.524602 17.4723 1.02327C18.2611 1.51287 18.8323 2.211 19.1859 3.11767H17.3227C17.0779 2.6462 16.7379 2.2926 16.3027 2.05687C15.8675 1.81207 15.3643 1.68967 14.7931 1.68967C14.1675 1.68967 13.6099 1.8302 13.1203 2.11127C12.6307 2.39234 12.2454 2.79127 11.9643 3.30807C11.6923 3.82487 11.5563 4.42327 11.5563 5.10327C11.5563 5.78327 11.6923 6.3862 11.9643 6.91207C12.2454 7.42887 12.6307 7.8278 13.1203 8.10887C13.6099 8.38994 14.1675 8.53047 14.7931 8.53047C15.3643 8.53047 15.8675 8.4126 16.3027 8.17687C16.7379 7.93207 17.0779 7.57394 17.3227 7.10247H19.1859C18.8323 8.00914 18.2611 8.70727 17.4723 9.19687C16.6926 9.68647 15.7995 9.93127 14.7931 9.93127C13.9046 9.93127 13.0931 9.72727 12.3587 9.31927C11.6243 8.9022 11.0395 8.32647 10.6043 7.59207C10.1782 6.85767 9.96511 6.02807 9.96511 5.10327C9.96511 4.17847 10.1782 3.34887 10.6043 2.61447C11.0395 1.88007 11.6243 1.30887 12.3587 0.900869C13.1022 0.483802 13.9136 0.275269 14.7931 0.275269Z' fill='white'/%3E%3C/svg%3E%0A");
        background-color: #1AB900;
        &:hover{
            background-color: #1AB900;
        }
    }
}

.cfgrangeslider-container {
    display: flex;
    align-items: center;
    height: 18px;
    margin-bottom: 40px;
    
    .cfgrangeslider {
        position: relative;
        width: 100%;
    }
      
    .cfgrangeslider__track,
    .cfgrangeslider__range,
    .cfgrangeslider__left-value,
    .cfgrangeslider__right-value {
        position: absolute;
    }
      
    .cfgrangeslider__track,
    .cfgrangeslider__range {
        border-radius: 3px;
        height: 10px;
    }
      
    .cfgrangeslider__track {
        background-color: #d3d6e4;
        width: 100%;
        z-index: 1;
    }
      
    .cfgrangeslider__range {
        background-color: #363F6C;
        z-index: 2;
    }
      
    .cfgrangeslider__left-value,
    .cfgrangeslider__right-value {
        color: #363f6c;
        font-size: 12px;
        margin-top: 20px;
    }
      
    .cfgrangeslider__left-value {
        left: 6px;
    }
      
    .cfgrangeslider__right-value {
        right: -4px;
    }
      
    /* Removing the default appearance */
    .cfg-thumb,
    .cfg-thumb::-webkit-slider-thumb {
        -webkit-appearance: none;
        -webkit-tap-highlight-color: transparent;
    }
       
    .cfg-thumb {
        pointer-events: none;
        position: absolute;
        height: 0;
        width: 100%;
        outline: none;
        z-index: 40;
    }
      
       
       /* For Chrome browsers */
    .cfg-thumb::-webkit-slider-thumb {
        background-color: #363F6C;
        border: 5px solid #fbdb03;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        width: 20px;
        margin-top: 10px;
        pointer-events: all;
        position: relative;
    }
       
       /* For Firefox browsers */
    .cfg-thumb::-moz-range-thumb {
        background-color: #363F6C;
        border: 5px solid #fbdb03;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        width: 20px;
        margin-top: 10px;
        pointer-events: all;
        position: relative;
    }
}

.fantacy-selected-list{       
    position: relative;
    padding-bottom: 80px;
    &-wrap{
        max-height: 70vh;
        overflow-y: auto;
        &::-webkit-scrollbar {
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }
    }
}