.gallery-grid{
    display: grid;
    grid-gap: 20px;
    @media screen and (min-width:600px){
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    @media screen and (min-width:900px){
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }    
    
    &-item{
        width: 100%;
        display: inline-block;
        position: relative;
        padding-top: calc( calc( 265/295 ) * 100% );
        grid-row-end: span 12;
        &:nth-child(odd){
            padding-top: calc( calc( 350/295 ) * 100% );
            grid-row-end: span 16;
        }
        > img{
            position: absolute;
            left: 0;
            top: 0;
            object-fit: cover;
            overflow: hidden;
            width: 100%;
            height: 100%;
        }
        &.short{
            grid-row: span 10;
        }
        &.long{
            grid-row: span 14;
        }
    }
}