@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter&family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");
@import "./utils/variables";

@import "./utils/utils.scss";
@import "./base/base";

html {
  font-family: "Poppins", sans-serif;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  min-height: 100vh;
}

/*#root{
  min-height: 100vh;
}
.App{
  min-height: 100vh;
}*/
#page {
  @media screen and (min-width: 1024px) {
    margin-left: 18.5rem;
    &.thin {
      margin-left: rem-calc(88px);
    }
  }
}
.page-header {
  background-image: url("../images/header-bg.png");
  background-position: top right;
  background-repeat: no-repeat;
  background-color: #363f6c;
  background-size: cover;
  color: var(--color-white);

  @media screen and (min-width: 1024px) {
    height: 248px;
  }
  &.event-detail {
    height: auto;
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
    h1 {
      font-size: rem-calc(22px);
      line-height: rem-calc(33px);
      margin-left: 0;
    }
  }
  h1 {
    font-weight: 500;
    font-size: rem-calc(28px);
    line-height: rem-calc(42px);
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      font-size: rem-calc(32px);
      line-height: rem-calc(48px);
      margin-bottom: 0;
    }
    @media screen and (min-width: 1280px) {
      font-size: rem-calc(34px);
      line-height: rem-calc(51px);
    }
  }
  .group-desc {
    font-weight: 400;
    font-size: rem-calc(14px);
    line-height: rem-calc(20px);
    margin-bottom: 20px;
    @media screen and (min-width: 768px) {
      margin-bottom: 0;
    }
  }
}

.popup-form {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(4px);
  z-index: 100000000;
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: center;
  opacity: 1;
  //animation: appear-static 0.3s ease-in-out forwards;
  overflow: auto;
  text-align: left;
  transform: translateZ(0);

  &-wrap {
    width: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
    max-width: 98vw;
    margin: auto 0;
    padding: 30px 20px 30px 20px;
    /* overflow: scroll; */
    opacity: 1;
    //animation: appear 0.4s ease-out forwards;
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 768px) {
      max-width: 90vw;
    }
    @media screen and (min-width: 1024px) {
      padding: 30px 30px 42px 30px;
    }
  }
  &-linkform {
    width: 98vw;
    max-width: 890px;
  }
  &-wallet {
    width: 98vw;
    max-width: 550px;
  }
  &-memberform {
    width: 98vw;
    max-width: 890px;
  }
  &-group {
    width: 98vw;
    max-width: 550px;
  }
  &-product {
    width: 98vw;
    max-width: 900px;
  }
  &-memberdetails {
    width: 90vw;
    max-width: 575px;
    padding: 30px 40px;
    @media screen and (min-width: 1024px) {
      padding: 30px 55px;
    }
    .close-popup {
      top: -15px;
      right: -15px;
      background-color: var(--color-darkblue);
      background-image: url("data:image/svg+xml,%3Csvg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 1.5L1 13.5' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M1 1.5L13 13.5' stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      &:hover {
        background-color: var(--color-yellow);
      }
    }
    .member-name {
      font-size: 18px;
      line-height: 27px;
      text-transform: capitalize;
      font-weight: 500;
      margin-bottom: 0;
    }
    .member-detail {
      margin-bottom: 4px;
      font-weight: 500;
    }
    .member-role {
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
    }
    .member-birthdate,
    .member-phone {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-darkblue);
      letter-spacing: 0.3px;
    }
  }
}
.mobile-nav {
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 5.995C2 5.445 2.446 5 2.995 5H11.005C11.2689 5 11.522 5.10483 11.7086 5.29143C11.8952 5.47803 12 5.73111 12 5.995C12 6.25889 11.8952 6.51197 11.7086 6.69857C11.522 6.88517 11.2689 6.99 11.005 6.99H2.995C2.73111 6.99 2.47803 6.88517 2.29143 6.69857C2.10483 6.51197 2 6.25889 2 5.995V5.995ZM2 12C2 11.45 2.446 11.005 2.995 11.005H21.005C21.2689 11.005 21.522 11.1098 21.7086 11.2964C21.8952 11.483 22 11.7361 22 12C22 12.2639 21.8952 12.517 21.7086 12.7036C21.522 12.8902 21.2689 12.995 21.005 12.995H2.995C2.73111 12.995 2.47803 12.8902 2.29143 12.7036C2.10483 12.517 2 12.2639 2 12V12ZM2.995 17.01C2.73111 17.01 2.47803 17.1148 2.29143 17.3014C2.10483 17.488 2 17.7411 2 18.005C2 18.2689 2.10483 18.522 2.29143 18.7086C2.47803 18.8952 2.73111 19 2.995 19H15.005C15.2689 19 15.522 18.8952 15.7086 18.7086C15.8952 18.522 16 18.2689 16 18.005C16 17.7411 15.8952 17.488 15.7086 17.3014C15.522 17.1148 15.2689 17.01 15.005 17.01H2.995Z' fill='black'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  @media screen and (min-width: 1024px) {
    display: none;
  }
  &.expand {
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1196_16297)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M11.9993 13.414L17.6563 19.071C17.8449 19.2532 18.0975 19.3539 18.3597 19.3517C18.6219 19.3494 18.8727 19.2442 19.0582 19.0588C19.2436 18.8734 19.3487 18.6226 19.351 18.3604C19.3533 18.0982 19.2525 17.8456 19.0703 17.657L13.4133 12L19.0703 6.343C19.2525 6.15439 19.3533 5.90179 19.351 5.6396C19.3487 5.3774 19.2436 5.12659 19.0582 4.94118C18.8727 4.75577 18.6219 4.6506 18.3597 4.64832C18.0975 4.64604 17.8449 4.74684 17.6563 4.929L11.9993 10.586L6.34233 4.929C6.15288 4.75134 5.90175 4.65436 5.64207 4.65858C5.38238 4.6628 5.13453 4.76788 4.95094 4.95159C4.76736 5.1353 4.66245 5.38323 4.65842 5.64292C4.65439 5.9026 4.75155 6.15367 4.92933 6.343L10.5853 12L4.92833 17.657C4.83282 17.7492 4.75664 17.8596 4.70423 17.9816C4.65182 18.1036 4.62424 18.2348 4.62308 18.3676C4.62193 18.5004 4.64723 18.6321 4.69751 18.755C4.74779 18.8778 4.82205 18.9895 4.91594 19.0834C5.00983 19.1773 5.12148 19.2515 5.24438 19.3018C5.36728 19.3521 5.49896 19.3774 5.63174 19.3762C5.76452 19.3751 5.89574 19.3475 6.01774 19.2951C6.13974 19.2427 6.25009 19.1665 6.34233 19.071L11.9993 13.414Z' fill='black'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1196_16297'%3E%3Crect width='24' height='24' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
  }
}
@keyframes appear-static {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.sort-actions {
  min-width: 200px;
  border: 0.8px solid rgba(0, 0, 0, 0.1);
  margin-top: 5px;
  z-index: 1000;
  li {
    padding: 4px 10px;
    &:hover {
      background-color: var(--color-yellow);
      color: var(--color-darkblue);
    }
    &.active {
      background-color: var(--color-darkblue);
      color: var(--color-white);
    }
  }
}
.cart-form,
.cart-detail {
  max-width: 500px;
}
.cart-detail {
  &-row {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.15);
  }
  &-label {
    font-weight: 400;
    text-transform: uppercase;
    font-size: rem-calc(16px);
    line-height: 1.33;
    letter-spacing: 0.5px;

    @media screen and (min-width:768px) {
      font-size: rem-calc(18px);
    }
  }
  &-value {
    font-weight: 500;
    font-size: rem-calc(18px);
    line-height: 1;
    letter-spacing: 0.3px;
    @media screen and (min-width:768px) {
      font-size: rem-calc(22px);
    }
  }
  &-total {
    &-label {
      font-weight: 600;
      text-transform: uppercase;
      font-size: rem-calc(18px);
      line-height: 1.33;
      letter-spacing: 0.5px;
      @media screen and (min-width:768px) {
        font-size: rem-calc(22px);
      }
    }
    &-value {
      font-weight: 600;
      font-size: rem-calc(26px);
      line-height: 1;
      letter-spacing: 0.3px;
      text-align: right;
      @media screen and (min-width:768px) {
        font-size: rem-calc(33px);
      }
    }
  }
}
.product-info-head {
  h1 {
    font-weight: 600;
    font-size: rem-calc(20px);
    line-height: rem-calc(30px);
    margin: 0 0 14px 0;
    color: var(--color-black);
  }
  .product-price {
    font-weight: 700;
    font-size: rem-calc(30px);
    line-height: rem-calc(45px);
    color: var(--color-darkblue);
  }
  .product-detail {
    font-weight: 500;
    font-size: rem-calc(14px);
    line-height: rem-calc(30px);
  }
}

.search {
  &-bar {
    width: 100%;
    max-width: 297px;
    background: #f4f5fa;
    border-radius: 110px;
  }
  &-icon {
    width: 42px;
    height: 21px;
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 17px;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='7.84845' cy='8.0704' r='7.0704' stroke='%23363F6C' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M12.7661 13.3552L15.5381 16.12' stroke='%23363F6C' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  }
  &-input {
    font-weight: 300;
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: var(--color-black);
  }
  &-input::-webkit-input-placeholder {
    font-weight: 300;
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: #abafb1;
  }

  &-input:-ms-input-placeholder {
    font-weight: 300;
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: #abafb1;
  }

  &-input::placeholder {
    font-weight: 300;
    font-size: rem-calc(14px);
    line-height: rem-calc(21px);
    color: #abafb1;
  }
}
.events-import {
  width: 100%;
  overflow-x: auto;
  table {
    min-width: 1000px;
  }
}
.payment-card {
  background-image: url("../images/payment-card-bg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 280px;
  height: 175px;
}

.fr {
  &-dib {
    display: block;
    margin: 0 auto;
  }
  &-fir {
    margin-left: auto;
    margin-right: 0;
  }
  &-fil {
    margin-left: 0;
    margin-right: auto;
  }
}

.react-datepicker__navigation--years-previous{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-down' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
}

.react-datepicker__navigation--years-upcoming{
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-chevron-up' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z'/%3E%3C/svg%3E") !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
}

.subscription-plan-description{
  font-size: 14px;
  line-height: 16px;
  ul{
    li{
      position: relative;
      padding-left: 15px;
      margin-bottom: 10px;
      &:before{
        position: absolute;
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 100%;
        top: 6px;
        left: 0;
        background-color: var(--color-yellow);
      }
    }
  }
}

.ads{
  img{
    width: 100%;
  }
}

.custom-scroll{
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
      border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(54, 63, 108,1); 
  }
}

.custom-scrollX{
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 2px;
  }

  &::-webkit-scrollbar-track {
      border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 2px;
      background-color: rgba(54, 63, 108,1); 
  }
}