.pdf-view{
    border: 1px solid rgba(0,0,0,0.1);
    border-radius: 8px;
    overflow: hidden;
    svg{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        object-fit: cover;
    }
}
.react-pdf{
    &__Page__textContent{
        display: none;
    }
    &__Page__annotations{
        display: none;
    }
    &__Page__svg{
        width: 150px !important;
        height: 180px !important;
    }
}