.calander-bookings-view{
    .react-calendar{
        font-family: "Poppins", sans-serif;
        width: 100%;
        max-width: 480px;
        border-radius: 20px;
        background-color: var(--color-darkblue);
        &__tile{
            width: 50px;
            height: 50px;
            position: relative;
            color: var(--color-white);
            abbr{
                width: 28px;
                height: 28px;
                padding: 5px;
                font-weight: 300;
                font-size: 16px;
                line-height: 16px;
                display: block;
                margin: 0 auto;
            }
            &--active{
                background-color: var(--color-darkblue);
                abbr{
                    font-weight: bold;
                    color: var(--color-darkblue);
                    background-color: var(--color-yellow);
                    border-radius: 100%;                
                }
            }
            &--now{
                color: var(--color-yellow);
                font-weight: 500;
                background-color: var(--color-darkblue);
            }
        }

        &__tile:enabled:hover, 
        &__tile:enabled:focus{
            background-color: var(--color-darkblue);
        }

        &__month-view__weekdays{
            text-transform: none;
            color: var(--color-white);
            font-size: 14px;
            line-height: 16px;
            
            abbr:where([title]){
                text-decoration: none;
                font-weight: 500;
            }            
        }
        
        &__navigation{
            button:enabled:hover,
            button:enabled:focus{
                background-color: var(--color-darkblue);
            }
        }  
        
        &__month-view__days__day--neighboringMonth{
            opacity: 0.5;
        }

        &__navigation__label{
            color: var(--color-white);
            font-weight: 500;
        }
        &__navigation{
            button{
                color: var(--color-white);
                font-size: 18px;
            }
        }        
    }  
    
}
.loading-overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    background-color: rgba(255,255,255,0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    .loading{
        width: 80px;
        height: 80px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' style='margin:auto;background:transparent;display:block;' preserveAspectRatio='xMidYMid' viewBox='26.5 26.5 47 47'%3E%3Ccircle cx='50' cy='50' r='22' stroke-width='3' stroke='%23000000' stroke-dasharray='34.55751918948772 34.55751918948772' fill='none' stroke-linecap='round'%3E%3CanimateTransform attributeName='transform' type='rotate' repeatCount='indefinite' dur='1s' keyTimes='0;1' values='0 50 50;360 50 50'%3E%3C/animateTransform%3E%3C/circle%3E%3C/svg%3E");
    }
}
.booking-card{
    box-shadow: 0px 10px 34px 0px rgba(197, 207, 255, 0.2);
    border-radius: 20px;
}