@import '../../assets/styles/utils/utils.scss';

.plaimap{
    position: relative;
    height: 350px;
    width: 100%;
    max-width: 525px;
    border: 1px solid rgba(54, 63, 108, 0.5);
    border-radius: 12px;
    overflow: hidden;
    
    @media screen and (min-width: 1280px) {
        height: 465px;
        max-width: 465px;
    }
    .google-map {
        width: 100%;
        height: 100%;        
        .pin {
            display: flex;
            align-items: center;
            width: 200px;
        }      
        .pin-text {
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
            color: var(--color-dark-blue);
        }
    }
}