@import 'rsuite/dist/rsuite-no-reset.min.css';

select,
textarea,
input {
    font-family: 'Poppins', sans-serif;
}
input::-ms-reveal,
input::-ms-clear {
    display: none;
}
input[type="text"],
input[type="tel"],
input[type="email"],
input[type="password"],
input[type="url"],
input[type="date"],
input[type="number"],
input[type="search"],
.virtual-text-field{
    width: 100%;
    background: #EFF1F9;
    border-radius: 8px;
    padding: 14px 16px 14px 45px;
    font-weight: 400;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    color: var(--color-gray-dark);

    @include placeholder{
        color: var(--color-gray-light);
    }

    &:focus{
        outline: none;
        background: #f5f7fb;
    }
    &.default-field-style{
        padding: 8px;
        font-size: rem-calc(12px);
        line-height: rem-calc(18px);
        background: #ffffff;
        border: 1px solid rgba(54, 63, 108, 0.6);        
    }
}
.virtual-text-field{
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    span{
        margin: 0 1px;
        background-color: hsl(0, 0%, 90%);
        font-size: 14px;
        line-height: 14px;
        padding: 4px;
    }
}
.rc-time-picker-input{
    height: auto !important;
    border: none !important;
}

input[type="checkbox"]{
    border: 1px solid var(--color-gray-light);
    &:checked{
        background-color: var(--color-darkblue);
        border-color: var(--color-darkblue);
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: center;
    }
    &:focus{
        outline: none;
    }
}
textarea{
    width: 100%;
    background: #EFF1F9;
    border-radius: 8px;
    padding: 14px 16px 14px 14px;
    font-weight: 400;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    color: var(--color-gray-dark);
    min-height: 180px;

    @include placeholder{
        color: var(--color-gray-light);
    }

    &:focus{
        outline: none;
        background: #f5f7fb;
    }
}
.small-field-wrap{
    &:before{
        display: none;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="search"],
    select,
    textarea{
        padding: 5px 10px;
    }
}
select{
    width: 100%;
    background-color: #EFF1F9;
    border-radius: 8px;
    padding: 14px 16px 14px 45px;
    font-weight: 400;
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    color: var(--color-gray-dark);
    appearance: none;
    position: relative;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 9.5L12.5 15.5L18.5 9.5' stroke='%235E6366' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: right 15px center;
    &:focus{
        outline: none;
        background-color: #f5f7fb;
    }

    &.default-field-style{
        padding: 8px 22px 8px 8px;
        font-size: rem-calc(12px);
        line-height: rem-calc(18px);
        background: #ffffff;
        border: 1px solid rgba(54, 63, 108, 0.6);
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.5 9.5L12.5 15.5L18.5 9.5' stroke='%23363f6c99' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: right 8px center;
    }
}
.select-field-wrap{
    &:before{
        z-index: 1;
    }
}

.button-group-wrap{
    .button-grop-label{
        font-weight: 400;
        font-size: rem-calc(16px);
        line-height: rem-calc(24px);
        color: var(--color-black);
        &.gender-field-label{
            color: #53545C;
        }
    }
    .gender-label{
        padding-left: 18px;
        padding-right: 20px;        
    }
}

.form-wrapper{
    .error{
        color: var(--color-red);
        font-size: rem-calc(14px);
        line-height: rem-calc(14px);
    }       
}
.form-field-container{
    .label{
        color: #ffffff;
        font-weight: 400;
        font-size: rem-calc(12px);
        line-height: rem-calc(18px);
        padding-left: rem-calc(10px);
        @media screen and (min-width:1440px) {
            font-size: rem-calc(14px);
            line-height: rem-calc(21px);
        }
    }
}
.form-field-wrap{
    .rs-picker-default .rs-picker-toggle.rs-btn{
        border: 0;
        border-radius: 0;
        background-color: transparent !important;
        box-shadow:none;
        &:focus{
            outline: none;
        }
    }
    .rs-picker-toggle-textbox{
        color: var(--color-gray-dark);
    }
    .rs-picker-has-value .rs-btn .rs-picker-toggle-value, 
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
        color: var(--color-gray-dark);
    }

    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, 
    .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean, 
    .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, 
    .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean{
        display: none;
    }
    .rs-picker-date.rs-picker-toggle-wrapper{
        display: block;
        background: #EFF1F9;
        border-radius: 8px;
        padding: 14px 16px 14px 45px;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--color-gray-dark);
    }    
    
    .rs-picker-date.rs-picker-default .rs-btn, 
    .rs-picker-date.rs-picker-default .rs-picker-toggle,
    .rs-picker-date.rs-picker-input .rs-btn, 
    .rs-picker-date.rs-picker-input .rs-picker-toggle,
    .rs-input-group.rs-input-group-inside{
        width: 100%;
        background: #EFF1F9;
        border-radius: 8px;
        padding: 0;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5rem;
        color: var(--color-gray-dark);
        border:0;
    }
    .rs-picker-date .rs-picker-toggle-textbox,
    .rs-picker-date .rs-input{
        padding: 0;
        border: 0;
        background: transparent;
    }
        
    .filepond--wrapper{
        padding: 10px;
        background: #EFF1F9;
        border-radius: 8px;
    }
    .filepond--panel-root{
        background: #EFF1F9;
    }
    .filepond--drop-label{
        .upload-icon{
            width: 75px;
            height: 63px;
            background-image: url('../../images/icon-uploadimage.svg');
            background-position: center;
            background-repeat: no-repeat;
            margin-bottom: 12px;
        }
        .upload-text{
            h4{
                font-weight: 500;
                font-size: rem-calc(16px);
                line-height: rem-calc(24px);
                text-decoration: underline;
                color: var(--color-darkblue);
                margin-bottom: 3px;
                text-align: center;
            }
            p{
                color: #53545C;
                font-style: italic;
                font-weight: 300;
                font-size: rem-calc(12px);
                line-height: rem-calc(14px);
            }
        }
    }
    .filepond--root{
        border: 1px dashed #363F6C;
        min-height: 236px !important;
        border-radius: 8px;
        margin-bottom: 0;
        .filepond--drop-label {
            min-height: 236px !important;
        }       
    }
    
}
.auth-form-field-wrap,
.form-field-wrap{
    &:before{
        content: '';
        position: absolute;
        width: 24px;
        height: 24px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }
    &.check-field-wrap{
        padding: 14px 16px 14px 10px;
        &::before{
            display: none;
        }
        label{
            padding-left: 32px;
            font-weight: 400;
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);
            color: #53545C;
            cursor: pointer;
            position: relative;
            display: block;

            input[type=checkbox]{
                position: absolute;
                left:0;
                top: 3px;
                width: rem-calc(20px);
                height: rem-calc(20px);
                border: 2px solid #53545C;
                border-radius: 5px;
                &:checked{
                    background-color: #ffffff;
                    border-color: #53545C;
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66602 4.00005L4.16602 6.22228L8.33268 1.77783' stroke='%2353545C' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
    &.new-check-field-wrap{
        padding: 14px 16px 14px 10px;
        &::before{
            display: none;
        }
        label{
            padding-left: 32px;
            font-weight: 400;
            font-size: rem-calc(14px);
            line-height: rem-calc(24px);
            color: #ffffff;
            cursor: pointer;
            position: relative;
            display: block;

            @media screen and (min-width:1440px) {
                font-size: rem-calc(16px);
            }

            input[type=checkbox]{
                position: absolute;
                left:0;
                top: 3px;
                width: rem-calc(20px);
                height: rem-calc(20px);
                border: 2px solid #FBDB09;
                border-radius: 5px;
                &:checked{
                    background-color: transparent;
                    border-color: #FBDB09;
                    background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.66602 4.00005L4.16602 6.22228L8.33268 1.77783' stroke='%23FBDB09' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: center;
                }
                &:focus{
                    outline: none;
                }
            }
        }
    }
}
.email-field-wrap{
    &:before{
        content: '';
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-email.svg');        
    }
}
.user-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-user.svg');        
    }
}
.agent-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-agentcode.svg');        
    }
}
.sport-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-sport.svg');        
    }
}
.country-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-country.svg');        
    }
}
.publish-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-publish.svg');        
    }
}
.date-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-date.svg');        
    }
}
.time-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-time.svg');        
    }
}
.facebook-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-facebook.svg');        
    }
}
.twitter-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-twitter.svg');        
    }
}
.instagram-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-instagram.svg');        
    }
}
.youtube-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-youtube.svg');        
    }
}
.tiktok-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-tiktok.svg');        
    }
}
.website-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-field-website.svg');        
    }
}
.groups-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-groups.svg');        
    }
}
.password-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-password.svg');        
    }
}
.type-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-types.svg');        
    }
}
.requestjoin-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-requestjoin.svg');        
    }
}
.location-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-location.svg');        
    }
}
.capacity-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-capacity.svg');        
    }
}
.repeat-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-repeat.svg');        
    }
}
.productname-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-productname.svg');        
    }
}
.price-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-price-1.svg');        
    }
}
.stripe-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-stripe.svg');        
    }
}
.discount-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-discount.svg');        
    }
}
.inventory-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-inventory.svg');        
    }
}
.phone-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-phone.svg');
    }
}
.facility-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url('../../images/icon-facility.svg');        
    }
}
.questiontype-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.9744 2C13.7829 2 15.235 2.49561 16.3306 3.48684C17.4435 4.46068 18 5.8084 18 7.53001C18 9.23422 17.4522 10.5385 16.3566 11.4427C15.2611 12.3296 13.809 12.7731 12.0005 12.7731L11.8961 14.7816H8.68768L8.55725 10.3472H9.73108C11.244 10.3472 12.4091 10.1472 13.2265 9.74723C14.0438 9.34726 14.4524 8.61688 14.4524 7.55609C14.4524 6.79093 14.2264 6.18228 13.7742 5.73014C13.3395 5.27801 12.7395 5.05194 11.9744 5.05194C11.1744 5.05194 10.5484 5.26931 10.0963 5.70406C9.66152 6.13881 9.44414 6.73876 9.44414 7.50392H6.00093C5.98354 6.44313 6.20961 5.49538 6.67914 4.66066C7.14867 3.82595 7.83557 3.17382 8.73985 2.70429C9.66152 2.23476 10.7397 2 11.9744 2ZM10.2789 21.0159C9.61804 21.0159 9.07026 20.8159 8.63551 20.416C8.21815 19.9986 8.00947 19.4856 8.00947 18.877C8.00947 18.2683 8.21815 17.764 8.63551 17.364C9.07026 16.9467 9.61804 16.738 10.2789 16.738C10.9223 16.738 11.4527 16.9467 11.87 17.364C12.2874 17.764 12.4961 18.2683 12.4961 18.877C12.4961 19.4856 12.2874 19.9986 11.87 20.416C11.4527 20.8159 10.9223 21.0159 10.2789 21.0159Z' fill='%236E7079'/%3E%3C/svg%3E%0A");
    }
}
.amount-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3 6H21V18H3V6ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.7956 14.6839 13.5587 14.1213 14.1213C13.5587 14.6839 12.7956 15 12 15C11.2044 15 10.4413 14.6839 9.87868 14.1213C9.31607 13.5587 9 12.7956 9 12C9 11.2044 9.31607 10.4413 9.87868 9.87868C10.4413 9.31607 11.2044 9 12 9ZM7 8C7 8.53043 6.78929 9.03914 6.41421 9.41421C6.03914 9.78929 5.53043 10 5 10V14C5.53043 14 6.03914 14.2107 6.41421 14.5858C6.78929 14.9609 7 15.4696 7 16H17C17 15.4696 17.2107 14.9609 17.5858 14.5858C17.9609 14.2107 18.4696 14 19 14V10C18.4696 10 17.9609 9.78929 17.5858 9.41421C17.2107 9.03914 17 8.53043 17 8H7Z' fill='%236E7079'/%3E%3C/svg%3E%0A");
    }
}
.ccards-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_5317_4999)'%3E%3Cpath d='M10.2695 3.09021e-05C10.1464 0.00495398 10.0276 0.0271078 9.90394 0.0578771L1.5384 2.09603C0.548863 2.33849 -0.0702138 3.33665 0.172863 4.3268L1.34579 9.11572C1.41686 9.40098 1.55525 9.66505 1.74937 9.88582C1.94348 10.1066 2.18767 10.2776 2.46148 10.3846V9.2308C2.46148 7.53419 3.84179 6.15388 5.5384 6.15388H13.2886L12.1347 1.40372C12.0327 0.990812 11.7913 0.625723 11.4514 0.37002C11.1116 0.114316 10.6939 -0.0163583 10.2689 3.09021e-05H10.2695ZM11.019 2.65418L11.5193 4.75018L9.4424 5.24988L8.92302 3.17295L11.019 2.65418ZM5.5384 7.38465C4.51932 7.38465 3.69225 8.21172 3.69225 9.2308V14.1539C3.69225 15.173 4.51932 16 5.5384 16H14.1538C15.1729 16 15.9999 15.173 15.9999 14.1539V9.2308C15.9999 8.21172 15.1729 7.38465 14.1538 7.38465H5.5384ZM5.5384 8.36557H14.1538C14.6282 8.36557 15.019 8.75634 15.019 9.2308V9.84618H4.67317V9.2308C4.67317 8.75634 5.06394 8.36557 5.5384 8.36557ZM4.67317 11.6923H15.019V14.1539C15.019 14.6283 14.6282 15.0191 14.1538 15.0191H5.5384C5.30933 15.0178 5.09 14.9262 4.92802 14.7643C4.76604 14.6023 4.67446 14.383 4.67317 14.1539V11.6923Z' fill='%236E7079'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_5317_4999'%3E%3Crect width='16' height='16' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
    }
}
.product-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6 22C5.45 22 4.97933 21.8043 4.588 21.413C4.19667 21.0217 4.00067 20.5507 4 20V8C4 7.45 4.196 6.97933 4.588 6.588C4.98 6.19667 5.45067 6.00067 6 6H8C8 4.9 8.39167 3.95833 9.175 3.175C9.95833 2.39167 10.9 2 12 2C13.1 2 14.0417 2.39167 14.825 3.175C15.6083 3.95833 16 4.9 16 6H18C18.55 6 19.021 6.196 19.413 6.588C19.805 6.98 20.0007 7.45067 20 8V20C20 20.55 19.8043 21.021 19.413 21.413C19.0217 21.805 18.5507 22.0007 18 22H6ZM10 6H14C14 5.45 13.8043 4.97933 13.413 4.588C13.0217 4.19667 12.5507 4.00067 12 4C11.45 4 10.9793 4.196 10.588 4.588C10.1967 4.98 10.0007 5.45067 10 6ZM15 11C15.2833 11 15.521 10.904 15.713 10.712C15.905 10.52 16.0007 10.2827 16 10V8H14V10C14 10.2833 14.096 10.521 14.288 10.713C14.48 10.905 14.7173 11.0007 15 11ZM9 11C9.28333 11 9.521 10.904 9.713 10.712C9.905 10.52 10.0007 10.2827 10 10V8H8V10C8 10.2833 8.096 10.521 8.288 10.713C8.48 10.905 8.71733 11.0007 9 11Z' fill='%236E7079'/%3E%3C/svg%3E%0A");     
    }
}
.discountcode-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.38 20.4148C12.16 21.1951 13.43 21.1951 14.21 20.4148L20.42 14.2031C21.2 13.4229 21.2 12.1525 20.42 11.3723L12.63 3.58016C12.2501 3.20936 11.7408 3.00125 11.21 3L5 3C3.9 3 3 3.90025 3 5.00056V11.2123C3 11.7424 3.21 12.2526 3.59 12.6227L11.38 20.4148ZM7.25 6.00083C7.58152 6.00083 7.89946 6.13257 8.13388 6.36705C8.3683 6.60154 8.5 6.91957 8.5 7.25118C8.5 7.58279 8.3683 7.90082 8.13388 8.13531C7.89946 8.3698 7.58152 8.50153 7.25 8.50153C6.91848 8.50153 6.60054 8.3698 6.36612 8.13531C6.1317 7.90082 6 7.58279 6 7.25118C6 6.91957 6.1317 6.60154 6.36612 6.36705C6.60054 6.13257 6.91848 6.00083 7.25 6.00083Z' fill='%236E7079'/%3E%3C/svg%3E%0A");
    }
}
.discountcodetype-field-wrap{
    &:before{
        top: 14px;
        left: 16px;
        z-index: 1;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5975 2.58215L9.09092 4.08735C8.96927 4.20905 8.80436 4.2776 8.63229 4.278H6.26183C5.16593 4.278 4.278 5.16593 4.278 6.26183V8.63229C4.27766 8.80477 4.20913 8.97011 4.08735 9.09225L2.58082 10.5975C2.20892 10.9695 2 11.474 2 12C2 12.526 2.20892 13.0305 2.58082 13.4025L4.08735 14.9091C4.20905 15.0307 4.2776 15.1956 4.278 15.3677V17.7382C4.278 18.8341 5.16593 19.722 6.26183 19.722H8.63229C8.80477 19.7223 8.97011 19.7909 9.09225 19.9126L10.5975 21.4192C10.9695 21.7911 11.474 22 12 22C12.526 22 13.0305 21.7911 13.4025 21.4192L14.9091 19.9126C15.0307 19.791 15.1956 19.7224 15.3677 19.722H17.7382C18.8341 19.722 19.722 18.8341 19.722 17.7382V15.3677C19.7223 15.1952 19.7909 15.0299 19.9126 14.9077L21.4192 13.4025C21.7911 13.0305 22 12.526 22 12C22 11.474 21.7911 10.9695 21.4192 10.5975L19.9126 9.09092C19.791 8.96927 19.7224 8.80436 19.722 8.63229V6.26183C19.722 5.16593 18.8341 4.278 17.7382 4.278H15.3677C15.1952 4.27795 15.0297 4.20938 14.9077 4.08735L13.4025 2.58082C13.0305 2.20892 12.526 2 12 2C11.474 2 10.9695 2.21025 10.5975 2.58215ZM8.195 14.8598L14.8611 8.19366L15.805 9.13758L9.13892 15.8037L8.195 14.8598ZM8.66696 8.66696V10.0002H10.0002V8.66696H8.66696ZM13.9998 15.333H15.333V13.9998H13.9998V15.333Z' fill='%236E7079'/%3E%3C/svg%3E%0A");
    }
}
.passwordTrigger{
    width: 19px;
    height: 17px;
    top: 18px;
    right: 19px;
    position: absolute;
    display: block;
    cursor: pointer;
    background-image: url('../../images/icon-passwordtriggeropen.svg'); 
    &.toggled{
        background-image: url('../../images/icon-passwordtriggerclose.svg'); 
    }   
}
.editor {
    margin-bottom: 20px;
    width: 100%;
    z-index: 9999999999;
}

.form-heading{
    font-weight: 600;
    font-size: rem-calc(22px);
    line-height: rem-calc(33px);
    color: var(--color-black);
}

.react-datepicker-popper{
    z-index: 10000000 !important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle, 
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle{
    margin-left: -11px !important
}

.search-field{
    width: 190px;
    &:before{
        content: '';
        width: 12px;
        height: 100%;
        top: 0;
        left: 12px;
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='6.99404' cy='6.51357' r='5.51357' stroke='%2353545C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M10.8291 10.6347L12.9907 12.7907' stroke='%2353545C' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
        z-index: 1;
    }
    input{
        background: #F4F5FA;
        border-radius: 100px;
        padding: 9px 6px 9px 32px;
        font-weight: 400;
        color: #53545C;
        font-size: rem-calc(10px);
        line-height: rem-calc(14px);

        @media screen and (min-width:1024) {
            padding: 6px 6px 6px 32px;
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
        }
    }
}
.sports-btn-group{
    height: 475px;
    padding-bottom: 90px;
    overflow-y: scroll;
    position: relative;
    &::-webkit-scrollbar {
        width: 2px;
    }
    
    &::-webkit-scrollbar-track {
        border-radius: 2px;
    }
    
    &::-webkit-scrollbar-thumb {
        border-radius: 2px;
        background-color: rgba(54, 63, 108,1); 
    }

    label{
        input{
            position: absolute;
            opacity: 0;
            + span{
                padding: 12px 10px;
                border-radius: 8px;
                overflow: hidden;
                background-color: rgba(54, 63, 108, 0.6);
                color: var(--color-white);
                font-size: rem-calc(12px);
                line-height: rem-calc(12px);
                cursor: pointer;
            }
            &:checked + span {
                color: var(--color-white);
                background-color: var(--color-darkblue);
                box-shadow: 0px 20px 20px -16px rgba(54, 63, 108, 0.6);
            }
        }
    }
}

.rs-picker-date-menu{
    z-index: 100000000 !important;
}

.no-field-wrap{
    &:before{
        display: none;
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="password"],
    input[type="url"],
    input[type="date"],
    input[type="number"],
    input[type="search"],
    select,
    textarea{
        padding-left: 16px;
    }
}