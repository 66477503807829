@import '../../assets/styles/utils/utils.scss';

.admin-navbar{
    .title{
        font-weight: 500;
        font-size: rem-calc(16px);
        line-height: rem-calc(28px);
        color: var(--color-black);
        margin-right: 10px;

        @media screen and (min-width:768px) {
            font-size: rem-calc(20px);
            line-height: rem-calc(30px);
            margin-right: 0;
        }
    }
    .search-bar{
        max-width: 225px;
    }
}
.breadcrumb{
    border-top: 1px solid #F5F5F5;
    font-size: rem-calc(12px);
    line-height: rem-calc(15px);
    font-weight: 400;
    color: #8B8D97;    
    a{
        color: var(--color-darkblue);
        &:hover{
            color: #000000;
        }
    }
    .separator{
        font-family: 'Inter', sans-serif;
        padding: 0 12px;
    }
    .home{
        padding-left: 22px;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            width: 14px;
            height: 14px;
            left: 0;
            background-image: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.09615 12.5214V10.4768C5.09614 9.95873 5.51744 9.53781 6.03928 9.53455H7.9554C8.47957 9.53455 8.9045 9.95642 8.9045 10.4768V12.5155C8.90449 12.9649 9.26967 13.3301 9.72229 13.3333H11.0295C11.6401 13.3349 12.2262 13.0952 12.6584 12.6671C13.0907 12.2391 13.3337 11.6578 13.3337 11.0517V5.2439C13.3336 4.75426 13.115 4.28981 12.7367 3.97567L8.29564 0.449512C7.51933 -0.167252 6.41057 -0.147328 5.65725 0.496923L1.31167 3.97567C0.915486 4.28055 0.678694 4.74638 0.666992 5.2439V11.0458C0.666992 12.3091 1.69858 13.3333 2.97111 13.3333H4.24852C4.46644 13.3349 4.67598 13.25 4.83064 13.0976C4.9853 12.9452 5.07228 12.7378 5.07227 12.5214H5.09615Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}