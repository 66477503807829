@import '../../../assets/styles/utils/utils.scss';

.page-chat{
    .chat-conversation{
        @apply rounded-lg h-[80px] py-[9px] xl:py-3.5 px-[7px] xl:pl-3.5 xl:pr-2.5 flex justify-between mb-5;        
        box-shadow: 0 6px 10px 0 rgba(54,63,108,0.10);

        &-menu{
            @apply absolute rounded-[4px] border border-slate-300 bg-white z-10 top-8 lg:top-10 right-0;            
            width: 100px;

            &-item{
                @apply border-b border-slate-300 px-2.5 py-2 text-left text-[9px] lg:text-[12px] text-black hover:bg-[#363F6C] hover:text-white last:border-b-0; 
                foo: bar;               
            }
        }

        &-list{
            @apply overflow-y-auto;
            max-height:  70vh;

            &::-webkit-scrollbar {
                width: 5px;
                height: 4px;
            }
        
            /* Track */
            &::-webkit-scrollbar-track {
                border-radius: 5px;
            }
        
            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: gray;
                opacity: 0.4;
            }
            &::-webkit-scrollbar-thumb:hover {
                opacity: 1;
            }
        }
    }
    .chatboard{
        @apply rounded-md lg:rounded-[20px] w-full h-full flex flex-col h-[70vh];
        box-shadow: 0 6px 10px 0 rgba(54,63,108,0.10);

        &-heading{
            @apply bg-[#363F6C] rounded-t-md lg:rounded-t-[20px] lg:h-[82px];
            foo: bar;
        }

        &-message{
            &__container{
                @apply w-full flex-1 bg-white py-2.5 lg:py-0 px-2.5 lg:px-8 overflow-y-auto;
                foo: bar;
                
                &::-webkit-scrollbar {
                    width: 5px;
                    height: 4px;
                }
            
                /* Track */
                &::-webkit-scrollbar-track {
                    border-radius: 5px;
                }
            
                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: gray;
                    opacity: 0.4;
                }
                &::-webkit-scrollbar-thumb:hover {
                    opacity: 1;
                }

                > * {
                    @apply mb-5;
                }

                .timestamp{
                    @apply text-[10px] leading-[15px];
                    foo: bar;
                }
            }

            &__chats{
                @apply grid;
                foo: bar;
            }

            &__sender{
                @apply flex max-w-[233px] max-w-[440px] justify-self-start;
                foo: bar;

                &_body{
                    @apply flex-1;
                    foo: bar;
                }

                &_by{
                    @apply text-[7px] leading-[10px] lg:text-[12px] lg:leading-[18px] mb-[3px];
                    foo: bar;
                }

                &_msg{
                    @apply bg-[#363F6C] rounded-xl py-2 lg:py-3 pl-2 lg:pl-5 pr-5 lg:pr-10 text-[9px] lg:text-[14px] leading-[12px] lg:leading-[18px] text-white mb-[5px] relative;
                    foo: bar;

                    &.type-image{
                        @apply p-1 max-w-[300px];
                        foo: bar;
                    }
                }
            }
            &__recipient{
                @apply max-w-[188px] lg:max-w-[386px] justify-self-end;
                foo: bar;

                &_msg{
                    @apply bg-[#F1F2F5] rounded-xl py-2 lg:py-3 pl-2 lg:pl-5 pr-5 lg:pr-10 text-[9px] lg:text-[14px] leading-[12px] lg:leading-[18px] mb-[5px] relative;
                    foo: bar;

                    &.type-image{
                        @apply p-1 max-w-[300px];
                        foo: bar;
                    }
                }
            }
        }

        &-form{
            @apply bg-[#363F6C] rounded-b-md lg:rounded-b-[20px] lg:h-[85px] py-2.5 lg:py-5;
            foo: bar;

            &-attachment{
                @apply w-[32px] lg:w-[62px] pl-3 pr-1 lg:px-4;
                foo: bar;
            }

            &-field-wrapper{
                input[type=text]{
                    @apply px-2 lg:px-5 py-2 lg:py-3.5 bg-white text-[9px] lg:text-[14px] leading-[14px] leading-[18px] lg:font-medium;
                    foo: bar;
                }
            }

            &-submit{
                @apply w-[35px] lg:w-[82px] pl-2 pr-3 lg:px-4;
                foo: bar;
            }
        }
    }
    .chat-members-list-wrap{
        @apply lg:rounded-[20px] lg:shadow-[0_4px_34px_0_rgba(0,0,0,0.06)] w-full h-full flex flex-col lg:p-7;
        foo: bar;

        .chat-member-row{
            @apply rounded-xl text-[#363F6C] font-medium text-[9px] lg:text-[16px] leading-[14px] lg:leading-[24px] shadow-[0_6px_30px_0_rgba(54,63,108,0.10)] mb-2.5 lg:mb-5 px-2 lg:pl-3.5 lg:pr-5 py-2 bg-white;
            foo: bar;

            &.selected{
                @apply bg-[#363F6C] text-white;
                foo: bar;
            }
        }
    }
    
    .chat-message-detail{
        .chatboard-message__recipient_msg.type-image{
            @apply max-w-[200px];
            foo:bar;
        }
        .timestamp{
            @apply text-[10px] leading-[15px];
            foo: bar;
        }
    }
}