@use 'sass:math';

/* Center block
----------------------------------------------- */
@mixin center-block {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/* Resets
----------------------------------------------- */    
// reset <a> styles
@mixin link-reset {
    color: inherit;
    text-decoration: none;
}

// reset <li> styles
@mixin list-reset {
    list-style: none;
    margin: 0;
    padding: 0;
    text-indent: 0;
}

// reset <button> styles
@mixin button-reset {
    background: none;
    border: 0;
    border-radius: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

// reset <input> styles
@mixin input-reset {
    background: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
}

/* Center Positioning
----------------------------------------------- */
@mixin center($center-all: both) {
	position: absolute;
	@if ($center-all == both) {
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	@else if ($center-all == y) {
		top: 50%;
		transform: translateY(-50%);
	}
	@else if ($center-all == x) {
		left: 50%;
		transform: translateX(-50%);
	}
}

/* Tools
----------------------------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
@function strip-unit($num) {
	@return math.div($num, $num * 0 + 1);
}
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
@function -to-rem($value, $base: null) {
	/* Check if the value is a number */
	@if type-of($value) != 'number' {
	@warn inspect($value) + ' was passed to rem-calc(), which is not a number.';
	@return $value;
	}
	/* Transform em into rem if someone hands over 'em's */
	@if unit($value) == 'em' {
	$value: strip-unit($value) * 1rem;
	}
	/* Calculate rem if units for $value is not rem or em */
	@if unit($value) != 'rem' {
	$value: math.div(strip-unit($value), strip-unit($base)) * 1rem;
	}
	/* Turn 0rem into 0 */
	@if $value == 0rem {
	$value: 0;
	}
	@return $value;
}
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
$global-font-size: 16px;
@function rem-calc($values, $base: null) {
	$rem-values: ();
	$count: length($values);
	/* If no base is defined, defer to the global font size */
	@if $base == null {
		$base: $global-font-size;
	}
	/* If the base font size is a %, then multiply it by 16px
	 This is because 100% font size = 16px in most all browsers
	*/
	@if unit($base) == '%' {
		$base: ($base / 100%) * 16px;
	}
	/* Using rem as base allows correct scaling */
	@if unit($base) == 'rem' {
		$base: strip-unit($base) * 16px;
	}
	@if $count == 1 {
		@return -to-rem($values, $base);
	}
	@for $i from 1 through $count {
		$rem-values: append($rem-values, -to-rem(nth($values, $i), $base));
	}
	@return $rem-values;
}

@function em-calc($pixels, $base: 16) {
	@return ($pixels/$base) * 1em;
}

// Replace `$search` with `$replace` in `$string`
// Used on our SVG icon backgrounds for custom forms.
//
// @param {String} $string - Initial string
// @param {String} $search - Substring to replace
// @param {String} $replace ('') - New value
// @return {String} - Updated string
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace +
			str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@function svg-url($svg) {
	//
	//  Chunk up string in order to avoid
	//  "stack level too deep" error
	//
	$encoded: "";
	$slice: 2000;
	$index: 0;
	$loops: ceil(str-length($svg) / $slice);
	@for $i from 1 through $loops {
		$chunk: str-slice($svg, $index, $index + $slice - 1);
		//
		//   Encode (may need a few extra replacements)
		//
		$chunk: str-replace($chunk, '"', "'");
		$chunk: str-replace($chunk, "<", "%3C");
		$chunk: str-replace($chunk, ">", "%3E");
		$chunk: str-replace($chunk, "&", "%26");
		$chunk: str-replace($chunk, "#", "%23");
		$encoded: #{$encoded}#{$chunk};
		$index: $index + $slice;
	}
	@return url("data:image/svg+xml;charset=utf8,#{$encoded}");
}

// aspect ratio
@mixin aspect-ratio($width, $height) {
    padding-bottom: percentage($height / $width);
}

// unified transition for theme
@mixin transition($property:all) {
    transition: $property .2s ease-in-out;
}

// fix WP admin bar height on sticky menu
@mixin admin-bar-sticky-fix {
    .admin-bar & {
        top: 46px;
        @media screen and (min-width: 783px) {
            top: 32px;
        }
    }
}

// show text to screen readers only
@mixin visuallyhidden {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal;
}

// fix float overflow
@mixin clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }
}  

//Placeholder css
@mixin optional-at-root($sel) {
	@at-root #{if(not &, $sel, selector-append(&, $sel))} {
	  @content;
	}
  }
  
@mixin placeholder {
@include optional-at-root('::-webkit-input-placeholder') {
	@content;
}

@include optional-at-root(':-moz-placeholder') {
	@content;
}

@include optional-at-root('::-moz-placeholder') {
	@content;
}

@include optional-at-root(':-ms-input-placeholder') {
	@content;
}
}