@import '../../assets/styles/utils/utils.scss';

.body{
    background-color: var(--color-gray-extralight);
}
.p-all{
    padding:40px 20px 30px 20px;
    @media screen and (min-width:1024px) {
        padding:40px 30px 30px 30px;
    }
    @media screen and (min-width:1280px) {
        padding:98px 35px 30px 71px;
    }
}
.setting_tab_detail{
    .tabs-menu {
        border-bottom: 0;
        padding: 22px 0 0 0;
        @media screen and (min-width:1024px) {
            padding: 22px 30px 0 0;
        }
        .tabs-menu-item {
            font-size: rem-calc(16px);
            line-height: rem-calc(20px);
            opacity: 0.7;
            font-weight: 300;
            margin-bottom: 20px;
            margin-right: 0;
            text-overflow: inherit;
            white-space: normal;
            @media screen and (min-width:1024px) {
                font-size: rem-calc(18px);
                line-height: rem-calc(22px);
            }
            @media screen and (min-width:1280px) {
                font-size: rem-calc(22px);
                line-height: rem-calc(33px);
            }
            
            &.active{
                font-weight: 800;
                color: #363F6C;
                text-decoration: none;
                opacity: 1;
                &:after{
                    display: none;
                }
            }
        }
    }
}

.ln-header {
    h2 {
        color: var(--color-darkblue);
        font-size: rem-calc(18px);
        line-height: rem-calc(24px);
        font-weight: 600;
        @media screen and (min-width:1024px) {
            font-size: rem-calc(22px);
            line-height: rem-calc(33px);
            font-weight: 800;
        }
    }
    .sep {
        flex: 1 1;
        height: 1px;
        background: #6E7079;
    }
}
.team-header {
    color: #fff;
}
.my_order_bottom {
    .ordercube{
        .team-header {
            color: #fff;
        }
        h4 {
            font-weight: 600;
            font-size: rem-calc(38px);
            line-height: rem-calc(57px);
        }
        p {
            font-weight: 500;
            font-size: rem-calc(19px);
            line-height:rem-calc(29px);
        }
    }
}
.status-lable{
    font-size: rem-calc(10px);
    line-height: rem-calc(15px);
    font-weight: 500;          
    &.sucess{
        background-color: #E1FDE9;
        color: #257D3E;
    } 
    &.red{
        background-color: #FDE1E1;
        color: #A83B3B;
    }
    &.red-dark {
        background-color: #fdbcbc;
        color: #f01515;
    }
    &.info{
        background-color:  #EAEFFE;
        color:  #4A60A5;
    }
    &.orange {
        background-color: rgba(242, 169, 59, 0.2);
        color: #f2a93b;
    }   
}
.my_order_list_box{
    box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    .left_list_top {
        h4 {
            font-weight: 600;
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);   
            color: #363F6C;
        }
        h3 {
            font-weight: 700;
            font-size: rem-calc(24px);
            line-height: rem-calc(36px);
        }
    }
    .order_detail_box  {
        h4{
            font-weight: 500;
            font-size: rem-calc(14px);
            line-height: rem-calc(16px); 
        }
        p {
            font-weight: 300;
            font-size: rem-calc(10px);
            line-height: rem-calc(16px); 
        }
        a {
            font-weight: 600;
            font-size: rem-calc(16px);
            line-height: rem-calc(24px);  
            color: #363F6C;
            display: inline-block;
            margin-left: 5px;
            height: 20px;
        }
    }
}
label{
    font-size: rem-calc(16px);
    line-height: rem-calc(24px);
    color: #6E7079;
    margin-bottom: 5px;
}
.change_password{
   
    .error {
        font-size: rem-calc(10px);
        line-height: rem-calc(15px);
        color: #53545C;
    }
}
.logout-main {
    p {
        font-weight: 500;
        font-size: rem-calc(20px);
        line-height: rem-calc(36px);
    }
    h3{
        font-size: rem-calc(30px);
        line-height: rem-calc(36px);
        color: var(--color-darkblue);
        font-weight: 700;
    }
}
.btn-grey{
    background: rgba(110, 112, 121, 0.1);
    box-shadow: 0px 24px 30px -18px rgba(0, 0, 0, 0.3);
    border-radius: 100px;
    padding: 12px 44px;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    font-size: rem-calc(18px);
    line-height: rem-calc(27px);
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s;
    &:hover{
        background: #FBDB09;
        transition: 0.5s;
    }
}
.yellow-bg{
    background: #FBDB09;
    &:hover{
        background: rgba(110, 112, 121, 0.1);
        transition: 0.5s;
    }
}

.my_order_section{
    @media screen and (max-width: 1024.99px) {
        max-height: 800px;
        overflow-y: auto;
        overflow-x: hidden;
        padding: 0 20px 0 20px;
        &::-webkit-scrollbar {
            width: 5px;
        }
        
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
            border-radius: 10px;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
        }
    }    
}

.my-teams{
    .team{
        background: var(--color-white);
        box-shadow: 0px 10px 50px -10px rgba(54, 63, 108, 0.8);
        border-radius: 12px;
        display: flex !important;
        .members{
            img{
                display: inline-block;
                border: 2px solid #ffffff;
            }
        }
        .title{
            font-weight: 600;
            font-size: rem-calc(14px);
            line-height: rem-calc(21px);
            color: var(--color-black);
        }
        .role{
            font-weight: 500;
            font-size: rem-calc(12px);
            line-height: rem-calc(18px);
            letter-spacing: 0.3px;
            color: var(--color-darkblue);
            &.isadmin{
                color: var(--color-green);
                padding-left: 18px;
                background-position: left center;
                background-repeat: no-repeat;
                background-size: 11px;
                text-transform: uppercase;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.69069 0.666595H8.31469C10.1227 0.666595 11.3334 1.93593 11.3334 3.82393V8.1818C11.3334 10.0639 10.1227 11.3333 8.31469 11.3333H3.69069C1.88269 11.3333 0.666687 10.0639 0.666687 8.1818V3.82393C0.666687 1.93593 1.88269 0.666595 3.69069 0.666595ZM5.69594 7.59455L8.22927 5.06122C8.41061 4.87989 8.41061 4.58655 8.22927 4.39989C8.04794 4.21855 7.74927 4.21855 7.56794 4.39989L5.36527 6.60255L4.43194 5.66922C4.25061 5.48789 3.95194 5.48789 3.77061 5.66922C3.58927 5.85055 3.58927 6.14389 3.77061 6.33055L5.03994 7.59455C5.13061 7.68522 5.24794 7.72789 5.36527 7.72789C5.48794 7.72789 5.60527 7.68522 5.69594 7.59455Z' fill='%231AB900'/%3E%3C/svg%3E%0A");
            }
        }
    }
}

.membership-card{
    background-image: url("data:image/svg+xml,%3Csvg width='383' height='539' viewBox='0 0 383 539' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M192.004 188.973C189.997 192.785 188.561 195.225 187.38 197.766C139.711 298.894 92.062 400.022 44.3931 501.134C38.6287 513.349 33.7694 525.988 26.7263 537.644C17.0862 553.553 1.99657 564.616 -19.3099 567.649C-44.7674 571.275 -65.9362 563.515 -82.5013 547.284C-98.3975 531.732 -101.329 512.705 -92.8102 494.187C-44.9445 390.111 3.86555 286.34 52.2625 182.45C70.9917 142.263 88.9536 101.805 108.45 61.889C115.08 48.3183 123.304 34.5612 134.341 23.5148C171.091 -13.2668 241.05 -3.82996 262.494 41.0162C317.973 157.003 372.41 273.362 427.122 389.637C443.786 425.08 459.564 460.828 476.483 496.17C491.297 527.106 457.242 572.376 410.557 568.632C385.572 566.633 365.465 556.721 355.806 536.594C312.189 445.597 269.419 354.295 226.432 263.078C215.041 238.902 203.906 214.623 191.984 188.973H192.004Z' fill='url(%23paint0_linear_1802_23221)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1802_23221' x1='146.445' y1='0.827647' x2='347.873' y2='543.708' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-opacity='0.06'/%3E%3Cstop offset='1' stop-opacity='0.01'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
    background-position: top center;
    background-repeat: no-repeat;
    min-height: 538px;
    height: 100%;
    position: relative;

    &:after{
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 239px;
        height: 11px;
        background-image: url("data:image/svg+xml,%3Csvg width='239' height='11' viewBox='0 0 239 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M227.909 0.5H10.5913C4.88753 0.5 0.263672 5.12386 0.263672 10.8277H238.237C238.237 5.12386 233.613 0.5 227.909 0.5Z' fill='%23FBDB03'/%3E%3C/svg%3E%0A");
    }
}