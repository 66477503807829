@import '../../assets/styles/utils/utils.scss';

.latest-news{
    .ln{
        &-header{
            h2{
                font-weight: 500;
                font-size: rem-calc(18px);
                line-height: rem-calc(36px);
                color: var(--color-darkblue);
                @media screen and (min-width:768px){
                    font-size: rem-calc(28px);
                    line-height: rem-calc(42px);
                }
            }
            .sep{
                flex: 1;
                height: 1px;
                background:#6E7079;
            }
            .sortby{
                font-weight: 500;
                font-size: rem-calc(10px);
                line-height: rem-calc(16px);
                background-color: #FBDB03;
                box-shadow: 0px 18px 20px rgba(0, 0, 0, 0.05);
                border-radius: 48.4615px;
                background-position: 12px center;
                background-repeat: no-repeat;
                background-size: 20px;
                background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.382 10.8564H10.382C9.90877 10.8564 9.52496 11.2402 9.52496 11.7134C9.52496 12.1867 9.90877 12.5705 10.382 12.5705H13.382C13.8553 12.5705 14.2391 12.1867 14.2391 11.7134C14.2391 11.2402 13.8561 10.8564 13.382 10.8564ZM16.8103 3.99983H10.382C9.90877 3.99983 9.52496 4.38364 9.52496 4.8569C9.52496 5.33016 9.90877 5.71397 10.382 5.71397H16.8103C17.2836 5.71397 17.6674 5.33016 17.6674 4.8569C17.6674 4.38364 17.2844 3.99983 16.8103 3.99983ZM11.6679 14.2847H10.3579C9.88466 14.2847 9.50085 14.6685 9.50085 15.1417C9.50085 15.615 9.88466 15.9988 10.3579 15.9988H11.6679C12.1412 15.9988 12.5009 15.615 12.5009 15.1417C12.5009 14.6685 12.142 14.2847 11.6679 14.2847ZM15.0962 7.42811H10.382C9.90877 7.42811 9.52496 7.81191 9.52496 8.28517C9.52496 8.75844 9.90877 9.14224 10.382 9.14224H15.0962C15.5694 9.14224 15.9532 8.75844 15.9532 8.28517C15.9532 7.81191 15.5702 7.42811 15.0962 7.42811ZM6.93958 12L6.0718 12.9482V4.8577C6.0718 4.38364 5.6888 3.99983 5.21473 3.99983C4.74066 3.99983 4.35766 4.38364 4.35766 4.8577V12.9463L3.48988 12C3.32082 11.8157 3.08974 11.722 2.85752 11.722C2.64314 11.7218 2.43652 11.8022 2.27874 11.9474C1.92975 12.2674 1.90645 12.8103 2.226 13.1599L4.55883 15.7335C4.88345 16.0886 5.49786 16.0886 5.82274 15.7335L8.15557 13.1599C8.47537 12.8103 8.4518 12.2677 8.10284 11.9474C7.802 11.6277 7.26098 11.6518 6.93958 12Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
                @media screen and (min-width:768px){
                    font-size: rem-calc(12px);
                    line-height: rem-calc(18px);
                }
            }
        }
        &-item{
            border-radius: 14px;
            padding: 13px 22px 13px 11px;
            margin-bottom: 22px;
            > img{
                height: 44px;
                border: 0.5px solid rgba(54, 63, 108, 0.5);
            }            
            .informations{
                h5{
                    font-weight: 700;
                    font-size: rem-calc(14px);
                    line-height: rem-calc(17px);
                    color: var(--color-darkblue);
                }
                .latest-news-content{
                    font-weight: 300;
                    font-size: rem-calc(11px);
                    line-height: rem-calc(15px);
                    letter-spacing: -0.2px;
                    color: var(--color-black);
                }
                .ln{
                    &-bookmark{  
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 15px;
                        background-color: #F0F1F2;
                        background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.05238 1.07741C8.35073 0.159169 9.64979 0.159177 9.94815 1.07741L11.434 5.65029H16.2422C17.2077 5.65029 17.6091 6.88578 16.828 7.45328L12.9381 10.2795L14.4239 14.8524C14.7223 15.7706 13.6713 16.5342 12.8902 15.9667L9.00026 13.1405L5.11034 15.9667C4.32923 16.5342 3.27827 15.7706 3.57662 14.8524L5.06244 10.2795L1.17252 7.45328C0.391413 6.88578 0.792853 5.65029 1.75834 5.65029H6.56656L8.05238 1.07741ZM9.00026 1.80609L7.60889 6.08828C7.47547 6.49893 7.09279 6.77696 6.66101 6.77696H2.15844L5.80109 9.4235C6.15041 9.6773 6.29658 10.1272 6.16315 10.5378L4.77178 14.82L8.41444 12.1735C8.76375 11.9197 9.23677 11.9197 9.58609 12.1735L13.2287 14.82L11.8374 10.5378C11.7039 10.1272 11.8501 9.6773 12.1994 9.4235L15.8421 6.77696H11.3395C10.9077 6.77696 10.5251 6.49893 10.3916 6.08828L9.00026 1.80609Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
                        &.bookmarked{
                            background-color: var(--color-darkblue);
                            background-image: url("data:image/svg+xml,%3Csvg width='15' height='14' viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8.05238 1.07741C8.35073 0.159169 9.64979 0.159177 9.94815 1.07741L11.434 5.65029H16.2422C17.2077 5.65029 17.6091 6.88578 16.828 7.45328L12.9381 10.2795L14.4239 14.8524C14.7223 15.7706 13.6713 16.5342 12.8902 15.9667L9.00026 13.1405L5.11034 15.9667C4.32923 16.5342 3.27827 15.7706 3.57662 14.8524L5.06244 10.2795L1.17252 7.45328C0.391413 6.88578 0.792853 5.65029 1.75834 5.65029H6.56656L8.05238 1.07741Z' fill='%23FBDB09'/%3E%3C/svg%3E%0A");
                        }
                        &.loading{
                            background-color: #F0F1F2;
                            background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='loader-1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='12px' height='12px' viewBox='0 0 40 40' enable-background='new 0 0 40 40' xml:space='preserve'%3E%3Cpath opacity='0.2' fill='%23000' d='M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946 s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634 c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z'%3E%3C/path%3E%3Cpath fill='%23000' d='M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0 C22.32,8.481,24.301,9.057,26.013,10.047z'%3E%3CanimateTransform attributeType='xml' attributeName='transform' type='rotate' from='0 20 20' to='360 20 20' dur='0.5s' repeatCount='indefinite'%3E%3C/animateTransform%3E%3C/path%3E%3C/svg%3E") !important;
                        }
                    }
                    &-share{  
                        width: 20px;
                        height: 20px;
                        border-radius: 100%;
                        background-position: center;
                        background-repeat: no-repeat;
                        background-size: 20px;                  
                        background-image: url("data:image/svg+xml,%3Csvg width='21' height='21' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='10.3333' cy='10.5447' r='10' fill='%23363F6C' fill-opacity='0.1'/%3E%3Cpath d='M5.33322 10.8061C5.33322 11.6709 6.03701 12.3747 6.90185 12.3747C7.31701 12.3747 7.69244 12.21 7.97322 11.946L11.1058 13.7363C11.0964 13.8043 11.0849 13.8722 11.0849 13.9433C11.0849 14.8082 11.7886 15.512 12.6535 15.512C13.5183 15.512 14.2221 14.8082 14.2221 13.9433C14.2221 13.0785 13.5183 12.3747 12.6535 12.3747C12.2383 12.3747 11.8629 12.5394 11.5821 12.8035L8.44956 11.0137C8.45897 10.9452 8.47048 10.8772 8.47048 10.8061C8.47048 10.735 8.45897 10.667 8.44956 10.5985L11.5821 8.8087C11.8629 9.07275 12.2383 9.23745 12.6535 9.23745C13.5183 9.23745 14.2221 8.53366 14.2221 7.66883C14.2221 6.80399 13.5183 6.1002 12.6535 6.1002C11.7886 6.1002 11.0849 6.80399 11.0849 7.66883C11.0849 7.73994 11.0964 7.80791 11.1058 7.87641L7.97322 9.66621C7.68407 9.39153 7.30066 9.2381 6.90185 9.23745C6.03701 9.23745 5.33322 9.94124 5.33322 10.8061Z' fill='%23363F6C'/%3E%3C/svg%3E%0A");
                    }
                    &-date{
                        font-weight: 400;
                        font-size: rem-calc(9px);
                        line-height: rem-calc(11px);
                        color: var(--color-black);
                        opacity: 0.6;
                    }
                    &-video{
                        padding-left: 25px;
                        font-weight: 700;
                        font-size: rem-calc(10px);
                        line-height: rem-calc(20px);
                        color: var(--color-darkblue);
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: contain;    
                        background-image: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 10.8083C0 5.2855 4.47715 0.80835 10 0.80835C15.5228 0.80835 20 5.2855 20 10.8083C20 16.3312 15.5228 20.8083 10 20.8083C4.47715 20.8083 0 16.3312 0 10.8083Z' fill='%23363F6C'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M6.00003 6.80832C4.89546 6.80832 4.00003 7.70375 4.00003 8.80832V12.8083C4.00003 13.9129 4.89546 14.8083 6.00003 14.8083H14C15.1046 14.8083 16 13.9129 16 12.8083V8.80832C16 7.70375 15.1046 6.80832 14 6.80832H6.00003ZM11.4766 11.2412C11.8099 11.0487 11.8099 10.5676 11.4766 10.3751L9.2024 9.06214C8.86907 8.86969 8.4524 9.11025 8.4524 9.49515V12.1211C8.4524 12.506 8.86907 12.7466 9.2024 12.5541L11.4766 11.2412Z' fill='%23FBDB03'/%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
    .status span{
        padding: 6px 12px 6px 12px;
        border-radius: 100px;        
        &.publish{
            background-color: var(--color-status-light-green);
            color: var(--color-status-green);
            font-weight: 400;
            font-size: rem-calc(10px);
            line-height: rem-calc(15px)          
        }
        &.unpublish{
            background-color: var(--color-status-light-red);
            color: var(--color-status-red);
            font-weight: 400;
            font-size: rem-calc(10px);
            line-height: rem-calc(15px);                           
        }
    }
}